import abiToken from './abi-token';
import abiMasterchef from './abi-masterchef';
import abi from './abi';

var contract = {
    // provider : "https://data-seed-prebsc-1-s1.binance.org:8545", //testnet bsc
    provider : "https://bsc-dataseed1.binance.org:443",
    airdropContract: "0x6370569a77096007FB82fBdC49e3D9d886aFcD40",
    abi : abi,
    farms : {
        contract: "0xde03a12BB7D8B456967073eC809Bb4fe07589B65", // masterchef
        // contract: "0x4560F6b33F165D0DA784056031a75C948c11C78C", // masterchef
        // contract: "0xd039B5aC31D224Eb9f998714058dA803F4cfda68", // masterchef testnet
        allowance: "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        abi: abiMasterchef,
        lpToken: {
            longbnb :{
                pid: 0, 
                contract: "0xa52bc4d4ad0483820067bfde5b7637a5f30d3eed",
                // contract: "0xEE1a645fB18dBB0Db8B36c99D6aDca4fB827866F", // viva
                abi: abiToken 
            },
            btabnb :{
                pid: 1, 
                contract: "0x050f4e1e5ef352910493f8a8c9283d970c02a539", 
                // contract: "0x1E8412618de89ce022b727e7E1Aea2358C0B8238", //bmw
                abi: abiToken 
            },
            longbta :{
                pid: 2, 
                contract: "0xee4648b23005d82a80ae154561d7ac2845e72942",
                // contract: "0xfFbDd5466A337f003C3ea4dDEf3038162e6Bf0Ff", //rot
                abi: abiToken 
            },
            long :{
                pid: 3, 
                contract: "0xd6004836cb07d063f2cb498c25e351cc93194079",
                // contract: "0x975b32b486bdd61D3f68cD8A558799614a318BD2", //pors
                abi: abiToken 
            },
            bta :{
                pid: 4, 
                contract: "0xad9787017e82f6368bbe4893b475caada2258564", 
                // contract: "0x3cC844f1d113B7995ff59E14a66D2B570785D9db", //red
                abi: abiToken 
            }
        }
    }
}

export default contract;