<template>
  <div>
  <footer>
      <div class="footer">
        <div class="container">
          <div class="row my-5 align-items-center text-lg-start">
            <div class="col-xl-6 my-2">
              <a href="https://t.me/longasset" target="_blank" class="text-white social-text px-2"><i class="uil uil-telegram-alt"></i></a>
              <a href="https://twitter.com/longasset" target="_blank" class="text-white social-text px-2"><i class="uil uil-twitter"></i></a>
              <a href="http://reddit.com/r/longAsset" target="_blank" class="text-white social-text px-2"><i class="uil uil-reddit-alien-alt"></i></a>
              <a href="https://github.com/BitcoinAssetChain" target="_blank" class="text-white social-text px-2"><i class="uil uil-github-alt"></i></a>
              <a href="https://medium.com/@longasset" target="_blank" class="text-white social-text px-2"><i class="uil uil-medium-m"></i></a>
              <a href="https://bscscan.com/token/0xd6004836cb07d063f2cb498c25e351cc93194079" target="_blank" class="text-white social-text px-2 smartcontract">Smart Contract</a>
              <hr>
              <a href="mailto:support@longasset.io" target="_blank" class="d-block text-white mx-2 my-2 text-lead no-underline"><i class="uil uil-envelope"></i> support@longasset.io</a>
            </div>
            <div class="col-xl-6 my-2 text-lg-end">
              <a class="gold cursor" @click="disclaimerModal">Disclaimer</a> <span class="gold mx-3">|</span>
              <a href="https://longasset.io/img/document/Privacy-Policy-LONGASSET.pdf" class="gold" target="_blank">Privacy</a> <span class="gold mx-3">|</span>
              <a href="https://longasset.io/img/document/Terms-of-Service-LONGASSET.pdf" class="gold me-4" target="_blank">Terms of Service</a>
            <b class="text-white">Powered By <a href="#" class="gold">BTA</a></b>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!-- Modal -->
    <div class="modal fade"
        ref="modal"
        id="disclaimer"
        :class="{show, 'd-block': modalActive2}"
        role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div class="modal-content plat rounded-4 text-white">
            <div class="modal-body px-5">
              <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="disclaimerModal">x</button>
              <h1 class="mb-5 mt-2 gold">Disclaimer</h1>
              <p>This disclaimer made by LONGASSET is addressed to all users and explains how to use the Longasset.io Website. By accessing or continuing this Website, it means you as a user agree to be bound by these laws and conditions.</p>
              <p>And we also hoped that you will know what legal information in your country. The provisions on this website can change at any time without notice. Therefore, we recommend that you always read all the terms on our website pages. If you as a user do not agree with the terms that we have made, We recommend You do not continue or use this web system.</p>
              <p>And all the information on this Website is not for anyone living in an area that is contrary to valid legal permits.  We expressly say from the start, there is no guarantee of any kind, either expressed or implied.</p>
              <p>All the information and materials contained and published by the Website are only for providing information without any elements of invitation, offer, and recommendation to sell or buy investments, conduct transactions and conclude legal action in any form.</p>
              <p>If something happens that creates a risk to the user, such as the loss of information regarding proof of transfer or something else due to various reasons, we from LONGASSET are not fully responsible.</p>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'footer',
    data() {
        return {
            modalActive1: false,
            modalActive2: false,
            show: false,
        }
    },
    methods: {
        openModalConnectWallet() {
            const body = document.getElementById("connectwallet");
            this.modalActive1 = !this.modalActive1;
            this.modalActive1 ?
                body.classList.add("modal-open") :
                body.classList.remove("modal-open");
            setTimeout(() => (this.show = !this.show), 10);
        },
        disclaimerModal() {
            const body = document.getElementById("disclaimer");
            this.modalActive2 = !this.modalActive2;
            this.modalActive2 ?
                body.classList.add("modal-open") :
                body.classList.remove("modal-open");
            setTimeout(() => (this.show = !this.show), 10);
        },
    },
  };
</script>


<style scoped>
.cursor{
  cursor:pointer;
}
.smartcontract{
  text-decoration:none;
  font-size:18px;
}
.no-underline{
  text-decoration: none;
}
.no-underline:hover{
  color: gold!important;
}
@media(max-width:991px){
  .smartcontract{
  display:block;
  }
}
</style>
