<template>
  <div>
    <div class="container mb-2 mt-5">
      <div class="row">
        <div class="col-12 text-end p-0 mb-2">
          <!-- <a href="airdrop" class="plat gold px-3" style="text-decoration:none;"><i class="uil uil-rocket"></i> Airdrop</a> -->
        </div>
      </div>
    </div>
    <nav class="navbar navbar-light nav navbar-expand-lg">
      <div class="container plat">
        <a class="navbar-brand ms-5">
          <div class="d-flex flex-row">
            <div class="p-0 me-3">
              <img src="../../assets/img/logo/head.png" alt="" class="p80">
            </div>
            <div class="p-0">
              <img src="../../assets/img/logo/tagline.png" alt="" class="mt-1 p200">
            </div>
          </div>

        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"><i class="uil uil-bars gold"></i></span>
        </button>

        <form class="d-lg-flex flex-sm-row" id="navbarNavAltMarkup">
          <a href="/" class="me-5 text-nav gold">Home</a>
          <a href="/farms" class="me-5 text-nav gold">Farms <span class="badge hot-badge psce">HOT</span></a>
          <!-- <a href="#" class="me-5 text-nav gold">Docs</a> -->
          <!-- <a href="#" class="me-5 text-nav gold">Collectibles</a> -->
          <a href="/nft" class="me-5 text-nav gold">NFT Marketplace  <span class="badge hot-badge">Soon</span></a>
        </form>

      </div>
    </nav>
  </div>
</template>

<style scoped>
.p80{
    width: 80px;
}
.p200{
    width: 200px;
}
.psce{
    letter-spacing:1px;
}
</style>
