import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import UI from "./plugins/ui"
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueCookies from 'vue3-cookies'

let app = createApp(App);
app.use(UI);
app.use(Toast, {});
app.use(VueCookies);

app.use(router).mount("#app");
