<template>
    <div>
    <Header></Header>
    <section id="page__sell" class="mt-5 section">
      <div class="container">
          <span class="gold mb-2 nft-text t-box">GIF Artworks
            <div class="sign">
              <img src="../assets/img/icon/sign.png" alt="" width="48px" style="transform: rotate(15deg);">
            </div>
          </span>

          <div class="row">
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/gif-1.gif" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
                </div>
                <div class="col-xl-3 my-4">
                  <div class="card p-3 card-hover rounded-4 card-orange">
                    <p class="d-o">Display Only</p>
                    <div class="card rounded-4" style="border: 0;">
                      <img src="../assets/img/nft/gif-2.gif" alt="" width="100%" class="rounded-4">
                    </div>
                    <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                  </div>
                </div>
                <div class="col-xl-3 my-4">
                  <div class="card p-3 card-hover rounded-4 card-orange">
                    <p class="d-o">Display Only</p>
                    <div class="card rounded-4" style="border: 0;">
                      <img src="../assets/img/nft/gif-4.gif" alt="" width="100%" class="rounded-4">
                    </div>
                    <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                  </div>
                  </div>
                  <div class="col-xl-3 my-4">
                    <div class="card p-3 card-hover rounded-4 card-orange">
                      <p class="d-o">Display Only</p>
                      <div class="card rounded-4" style="border: 0;">
                        <img src="../assets/img/nft/gif-5.gif" alt="" width="100%" class="rounded-4">
                      </div>
                      <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                    </div>
                  </div>
          </div>


          <span class="gold mb-2 nft-text t-box">Picture Artworks
            <div class="sign">
              <img src="../assets/img/icon/sign.png" alt="" width="38px" style="transform: rotate(15deg);">
            </div>
          </span>
          <div class="row">
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/art/1.jpg" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
              </div>
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/art/2.jpg" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
              </div>
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/art/3.jpg" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
              </div>
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/art/4.jpg" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
              </div>
          </div>

      </div>
    </section>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
export default {
    name: 'nft',
    components: {
        Footer,
        Header
    },
    methods: {
         getImgUrl(index, type) {
            if(type == 1) return require('../assets/img/nft/art/style-'+index+'.jpg');
            if(type == 2) return require('../assets/img/nft/art/bta'+index+'.jpg');
        }
    },
    created() {
        document.title = 'Nft | Long Asset';
    },
}
</script>

<style scoped>
.headImg{
    width: 80px;
}
.taglineImg{
    width: 200px;
}
.sideImg{
    width: 100%;
}
</style>
