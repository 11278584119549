import { createWebHistory, createRouter } from "vue-router";
// import Home from "./components/Home.vue";
import Airdrop from "./components/Airdrop.vue";
import Farms from "./components/Farms.vue";
import Nft from "./components/Nft.vue";
import Index from "./components/Index.vue";

let routes = [
    // { path: "/", name: "Home", component: Home },
    { path: "/airdrop", name: "Airdrop", component: Airdrop },
    { path: "/farms", name: "Farms", component: Farms },
    { path: "/nft", name: "Nft", component: Nft },
    { path: "/", name: "Index", component: Index }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
