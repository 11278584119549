<template>
    <div>
      <Header></Header>
        <section id="page__airdrop" class="section">
            <div class="container my-5 text-center">
              <h1 class="gold mb-4">AIRDROP IS LIVE!</h1>
                <button v-if="params != null && teleJoined == false && webJoined == false"
                        class="text-center my-4 btn btn-todo med-text"
                        style="text-decoration:none;"
                        @click="joinAirdrop">
                    <i class="uil uil-rocket"></i> Join Airdrop
                </button>
                <a v-else class="text-center my-4 btn btn-todo med-text btn" href="https://t.me/longassetbot" style="text-decoration:none;">
                    <i class="uil uil-rocket"></i> Join Airdrop
                </a>
                <div class="plat gold px-3 py-5">
                    <img src="../assets/img/logo/side.png" class="sideImg">
                    <div class="row text-center my-4">
                        <div class="col-lg-3">
                            <h6 class="text-white">My Status</h6>
                            <h4>{{ myStatus }}</h4>
                        </div>
                        <div class="col-lg-3">
                            <h6 class="text-white">Total Participans</h6>
                            <h4>{{ totalParticipant }}</h4>
                        </div>
                        <div class="col-lg-3">
                            <h6 class="text-white">Total Reward</h6>
                            <h4><img src="../assets/img/ticker.png" alt="" class="w40"> 1,000,000 LONG</h4>
                        </div>
                        <div class="col-lg-3">
                            <h6 class="text-white">Distribution Date</h6>
                            <h4>April 30<sup>th</sup>, 2021</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
        <div class="modal fade"
            ref="modal"
            :class="{show, 'd-block': modalActive1}"
            role="dialog"
            id="modal"
         tabindex="-1" aria-labelledby="airdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalJoinAidrop">x</button>
                        <h3 class="mb-5 gold mt-2">Airdrop Form</h3>
                        <form class="" action="" method="post">
                            <h4 class="mt-5 gold">BSC Wallet BEP-20</h4>
                            <input type="text" name="" value="" class="form-control " placeholder="Example : 0xd6004836cb07d063f2cb498c25e351cc93194079">
                            <h4 class="mt-5 gold">Your Telegram ID</h4>
                            <input type="text" name="" value="" class="form-control" placeholder="Example : @LongAsset">
                            <a href="#" class="btn btn-todo mt-5 w-100">Submit</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade"
            :class="{show, 'd-block': modalActive2}"
            role="dialog"
            id="connectwallet"
            tabindex="-1" aria-labelledby="connectwalletlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalConnectWallet">x</button>
                        <h1 class="mb-3">Connect Wallet</h1>
                        <div class="d-flex flex-row my-4">
                            <div class="p-2">
                                <span class="bullet me-3">1</span>
                            </div>
                            <div class="p-2">
                                <p class="med-text">Accept <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a></p>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="on" checked id="accept" >
                                    <label class="form-check-label" for="accept">
                                    I read and accept
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row my-4">
                            <div class="p-2">
                                <span class="bullet me-3">2</span>
                            </div>
                            <div class="p-2">
                                <p class="med-text">Choose Wallet</p>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw1">
                                    <label class="form-check-label cb" for="cw1" @click="connectWallet('metamask')">
                                    <img src="../assets/img/metamask.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Metamask</p>
                                </div>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw2">
                                    <label class="form-check-label cb" for="cw2">
                                    <img src="../assets/img/trust.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Trust Wallet</p>
                                </div>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw3">
                                    <label class="form-check-label cb" for="cw3">
                                    <img src="../assets/img/tp.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Token Pocket</p>
                                </div>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw4">
                                    <label class="form-check-label cb" for="cw4">
                                    <img src="../assets/img/math.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Math Wallet</p>
                                </div>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw5">
                                    <label class="form-check-label cb" for="cw5">
                                    <img src="../assets/img/sf.jpg" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Safefal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>

    </div>
</template>

<script>
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import contractService from "../services/ContractService";
// import VueMetamask from 'vue-metamask';
import configContract from "../config/contract";
import Web3 from "web3";
import { useToast } from "vue-toastification";
export default {
    name: 'airdrop',
    setup() {
      const toast = useToast();
      return { toast }
    },
    data() {
        return {
            modalActive1: false,
            modalActive2: false,
            show: false,
            web3: null,
            MetaMaskId: "1", // main net netID
            netID: null, // user metamask id
            MetaMaskAddress: "", // user Address
            Web3Interval: null,
            AccountInterval: null,
            NetworkInterval: null,
            teleJoined: false,
            webJoined: false,
            params: null,
            myStatus: '-',
            totalParticipant: 0
        };
    },
    components: {
        Footer, Header
    },
    methods: {
        openModalJoinAidrop() {
            const body = document.getElementById("modal");
            this.modalActive1 = !this.modalActive1;
            this.modalActive1 ?
                body.classList.add("modal-open") :
                body.classList.remove("modal-open");
            setTimeout(() => (this.show = !this.show), 10);
        },
        openModalConnectWallet() {
            const body = document.getElementById("connectwallet");
            this.modalActive2 = !this.modalActive2;
            this.modalActive2 ?
                body.classList.add("modal-open") :
                body.classList.remove("modal-open");
            setTimeout(() => (this.show = !this.show), 10);
        },
        async connectWallet(wallet) {
            if(wallet != 'metamask') return;
            // this.toast.info("Login to metamask");
            if (window.ethereum) {
                window.web3 = new Web3(window.ethereum);
                try {
                    await window.ethereum.enable();
                    this.web3TimerCheck(window.web3);
                    var self = this;
                    setTimeout(function(){
                        self.toast.info("Success login to metamask");
                        self.openModalConnectWallet();
                    }, 300);
                } catch (error) {
                    console.log('refresh page', error)
                    console.log(
                        "USER_DENIED_ACCOUNT_AUTHORIZATION"
                    );
                }
            } else if (window.web3) {
                console.log('here 1')
                window.web3 = new Web3(Web3.currentProvider);
                this.web3TimerCheck(window.web3);
            } else {
                this.web3 = null;
                console.log("NO_INSTALL_METAMASK");
                console.error(
                    "Non-Ethereum browser detected. You should consider trying MetaMask!"
                );
            }
        },
        checkWeb3() {
            let web3 = window.web3;
            if (typeof web3 === "undefined") {
                this.web3 = null;
            }
        },
        checkAccounts() {
            if (this.web3 === null) return;
            this.web3.eth.getAccounts((err, accounts) => {
                console.log();
                if (err != null)
                    console.log("NETWORK_ERROR");
                if (accounts.length === 0) {
                    this.MetaMaskAddress = "";
                    console.log("NO_LOGIN");
                    this.netID = null;
                    this.web3 = null;
                    return;
                }
                this.MetaMaskAddress = accounts[0]; // user Address

                this.getInfo();
            });
        },
        checkNetWork() {
            if (this.web3 === null) return;
            this.web3.eth.getChainId((err, netID) => {
                // Main Network: 1
                // Ropsten Test Network: 3
                // Kovan Test Network: 42
                // Rinkeby Test Network: 4
                if (err != null)
                    console.log("NETWORK_ERROR");
                    this.netID = netID; //User MetaMask's current status
                // if (netID === 1) 1 for eth
                if (netID === 1072)
                    console.log("MAINNET");
                if (netID === 3)
                    console.log("ROPSTEN");
                if (netID === 42)
                    console.log("KOVAN");
                if (netID === 4)
                    console.log("RINKEBY");
                if (netID === 97)
                    console.log("BSC TESTNET");
            });

        },
        web3TimerCheck(web3) {
            this.web3 = web3;
            this.checkAccounts();
            this.checkNetWork();
            this.Web3Interval = setInterval(() => this.checkWeb3(), 5000);
            this.AccountInterval = setInterval(() => this.checkAccounts(), 5000);
            this.NetworkInterval = setInterval(() => this.checkNetWork(), 5000);
        },
        async joinAirdrop() {
            var params = this.$route.query.tme;
            if(!params){
                this.toast.error("user id not found");
                return;
            }

            if (this.web3){
                var myContract = new this.web3.eth.Contract(configContract.abi, configContract.airdropContract);
                var self = this;
                var metaAddress = this.MetaMaskAddress;
                myContract.methods.register(params).send({from: metaAddress}, function(err, hash){
                    if(err) self.toast.warn(err.message);
                    var data = {
                        address: metaAddress,
                        userid: params,
                        tx: hash
                    }
                    contractService.create(data)
                    .then(response => {
                        if(response.data.message){
                            self.toast.info("Success joined airdrop");
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        self.toast.error(e);
                    });
                });
            }
        },
        getInfo() {
            var params = this.$route.query.tme;
            if(!params) return;
            if (this.web3){
                var myContract = new this.web3.eth.Contract(configContract.abi, configContract.airdropContract);
                var accountAdd = this.MetaMaskAddress;
                var self = this;
                myContract.methods.registeredAddress(params).call({})
                    .then(function(result){
                        if(result == '0x0000000000000000000000000000000000000000'){
                            // not joined smart contract
                            self.myStatus = '-';
                            myContract.methods.registeredUserId(accountAdd).call({})
                            .then(function(result2){
                                if(result2 == 0){
                                    // not joined smart contract
                                    self.webJoined = false;
                                }else{
                                    self.webJoined = true;
                                }
                            });
                        }else{
                            self.myStatus = 'Joined';
                            self.webJoined = true;
                        }
                });

                var data = {
                    userid: params,
                    address: accountAdd
                }

                contractService.getUserSpecified(data)
                    .then(response => {
                        if(response.data.message){
                            // already joined telegram
                            var user = response.data.message[0];
                            if(user.web_tx){
                                // already joined smart contract and telegram
                                self.teleJoined = true;
                                self.myStatus = 'Joined';
                            }else{
                                // not joined web and telegram
                                self.myStatus = '-';
                                if(user.joined == 1){
                                    // already joined telegram
                                    self.teleJoined = false;
                                }else{
                                    self.teleJoined = true;
                                }
                            }
                        }
                        else{
                            // not joined in telegram
                            self.teleJoined = true;
                            self.myStatus = '-';
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },
        getParams() {
            var params = this.$route.query.tme;
            if(params) {
                this.params = params;
            }else{
                this.toast.error("it looks like your parameters are missing");
                this.params = null;
            }
        },
        getContractInformation() {
            var web3 = new Web3(new Web3.providers.HttpProvider(configContract.provider));
            var myContract = new web3.eth.Contract(configContract.abi, configContract.airdropContract);
            var self = this;
            myContract.methods._totalRegistered().call({})
                .then(function(participant){
                self.totalParticipant = participant;
            });
        }
    },
    mounted() {
        this.getParams();
        this.teleJoined = true;
        this.webJoined = true;
        this.getContractInformation();
    },
    created() {
        document.title = 'Airdrop | Long Asset';
    },
}
</script>

<style scoped>
.headImg{
    width: 80px;
}
.taglineImg{
    width: 200px;
}
.sideImg{
    width: 80%;
}
.w40{
  width: 40px;
}
</style>
