import http from "../config/http-common";

class ContractService {

  create(data) {
    return http.post("/register", data);
  }

  getUserSpecified(data) {
    return http.get(`/user?userid=${data.userid}&add=${data.address}`);
  }
}

export default new ContractService();