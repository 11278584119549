import axios from "axios";

class ApiServices {

  async longPrice() {
      return axios.get('https://api.dex.guru/v1/tokens/0xd6004836cb07d063f2cb498c25e351cc93194079-bsc');
  }

  async btaPrice() {
    return axios.get('https://api.dex.guru/v1/tokens/0xad9787017e82f6368bbe4893b475caada2258564-bsc');
  }

  async bnbPrice() {
    return axios.get('https://api.dex.guru/v1/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c-bsc');
  }
  
}

export default new ApiServices();