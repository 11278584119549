<template>
  <div>
    <Header></Header>
    <section id="page__x" class="section">
        <div class="container">
          <div class="row align-items-center animate__animated animate__fadeInUp text-start">
            <div class="col-xl-6 mb-5">
              <h1 class="text-white ultra-text"><a href="https://en.wikipedia.org/wiki/Non-fungible_token" target="_blank" class="gold" style="text-decoration:none;">First Literature Non-Fungible Token (NFT) in Crypto Assets</a></h1>
              <p class="text-white text-lead mt-4">Long Asset Token functions for Staking, Farming, and can also be used for transactions at the <span class="gold">LONG NFT Marketplace, Collectibles & NFTs.</span></p>
              <a href="https://pancakeswap.finance/" class="btn btn-endeavor w-64 mt-5" target="_blank">Exchanger</a>
            </div>
            <div class="col-xl-6 mb-5 text-end">
                <img src="../assets/img/logo/logo.png" alt="" width="80%" class="w-80">
            </div>
          </div>
        </div>
    </section>

    <section id="page__market" class="section animate__animated animate__fadeInUp">
        <div class="container plat p-0">
          <div class="row align-items-center">
              <div class="col-xl-6 py-3">
                  <img src="../assets/img/logo/side.png" alt="" width="100%" class="w-100">
              </div>
              <div class="col-xl-6 p-5">
                  <div class="text-center">
                      <h3 class="text-white"><b>Total Value Locked</b></h3>
                      <h1 class="ultra-text italic gold">${{ helperNumeral(info.TVL, '0,0') }}</h1>
                  </div>
              </div>
          </div>
        </div>
    </section>

    <section class="section">
      <div class="container my-5">
        <div class="row mt-3 animate__animated animate__fadeInUp animate__delay-1s align-items-center text-white text-start">
            <div class="col-lg-4">
              <img src="../assets/img/eye.png" alt="" width="100%" class="rounded-4 w-100">
            </div>
            <div class="col-lg-8 px-4">
              <h1 class="mb-4">Introduction</h1>
              <h5 class="gold"><b>Long Asset Token ( LONG ) </b></h5>
              <p>LONG Is a Defi NFT token powered by BTA in order to advance the Arts and FIRST Literature industry in the digital world. </p>
              <h6 class="gold"><b>Inspiration</b></h6>
              <p>As the name implies <span class="gold">"Long"</span> or <span class="gold">Dragon</span>, inspired by old Chinese Literature, Long Asset functions to provide advice / encourage communities, different every day that can be purchased with <span class="gold">Long Asset tokens</span>. In short, we can say that long assets have characteristics like Fortune Cookies.</p>
              <p>Initially, long assets could be traded on Pancakeswap with <span class="gold">BTA token pairs</span>. With over 1 Million first Liquidity in pancakeswap. In the future, Long Asset can also migrate with the main BTA-Chain network.</p>
              <a href="whitepaper/Whitepaper-Long-Asset.pdf" class="btn btn-todo mt-3" target="_blank">Whitepaper</a>
            </div>
        </div>
      </div>
    </section>

    <section class="section animate__animated animate__fadeInUp animate__delay-1s">
        <div class="container">
          <h1 class="mb-4 gold text-center">Digital Artworks</h1>
          <div class="line mb-5"></div>
          <div class="row mt-4">
            <div class="col-xl-3 my-4">
              <div class="card p-3 card-hover rounded-4 card-orange">
                <p class="d-o">Display Only</p>
                <div class="card rounded-4" style="border: 0;">
                  <img src="../assets/img/nft/gif-1.gif" alt="" width="100%" class="rounded-4">
                </div>
                <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
              </div>
              </div>
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/gif-2.gif" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
              </div>
              <div class="col-xl-3 my-4">
                <div class="card p-3 card-hover rounded-4 card-orange">
                  <p class="d-o">Display Only</p>
                  <div class="card rounded-4" style="border: 0;">
                    <img src="../assets/img/nft/gif-4.gif" alt="" width="100%" class="rounded-4">
                  </div>
                  <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                </div>
                </div>
                <div class="col-xl-3 my-4">
                  <div class="card p-3 card-hover rounded-4 card-orange">
                    <p class="d-o">Display Only</p>
                    <div class="card rounded-4" style="border: 0;">
                      <img src="../assets/img/nft/gif-5.gif" alt="" width="100%" class="rounded-4">
                    </div>
                    <h4 class="mt-2 text-center plat gold">Digital Artwork</h4>
                  </div>
                </div>
          </div>
          <div class="row my-3 text-white">
            <div class="col-xl-6 text-start">
              <h4 class="gold my-3">Support For other Token </h4>
              <p>Long acts as a complete facility to support other Token. Like a car, we are the drive in the form of wheels and we complete the other parts as 1 whole governance. <span class="gold">Staking, farming & Marketplace</span> in 1 complete platform.</p>
            </div>
            <div class="col-xl-6 text-start">
              <h4 class="gold my-3">NFT Marketplace</h4>
              <p>This marketplace will be a place for image and video artists to get value from the work created through <span class="gold">the Blockchain NFT BSC Smart chain</span>, in the future we will also be available on the <span class="gold">BTA Chain</span> this protocol will make our network bigger.</p>
            </div>
          </div>
        </div>
    </section>

    <section id="page__management" class="section">
      <h1 class="text-center mb-5 gold">Roadmap</h1>
      <div class="line mb-5"></div>
        <div class="container">
          <div class="row align-items-center animate__animated animate__fadeInUp">
              <div class="col-xl-4">
                <div class="plat gold px-4 phase">
                  <h3>Phase 1</h3>
                  <ul class="no-bullets">
                    <li><i class="uil uil-check-circle"></i> Create a Smart Contract in BSC network</li>
                    <li><i class="uil uil-check-circle"></i> Launch Website (also NFT Marketplace in the future)</li>
                    <li><i class="uil uil-check-circle"></i> Create LP in pancakeswap</li>
                    <li><i class="uil uil-check-circle"></i> Start Airdrop & Airdrop Distribution</li>
                    <li><i class="uil uil-check-circle"></i> Github Open Source</li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="plat gold px-4 phase">
                  <h3>Phase 2</h3>
                  <ul class="no-bullets">
                    <li><i class="uil uil-check-circle"></i> Farming pools Launch</li>
                    <li><i class="uil uil-check-circle"></i> Staking pools Launch</li>
                    <li><i class="uil uil-hourglass"></i> Audit on Third Party</li>
                    <li><i class="uil uil-check-circle"></i> Submit Logo to Dapp’s</li>
                    <li><i class="uil uil-hourglass"></i> Submit LONG Dapp’s</li>
                    <li><i class="uil uil-hourglass"></i> List to Coinmarketcap & Coingecko</li>
                    <li><i class="uil uil-hourglass"></i> Built NFT Staking & Marketplace</li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="plat gold px-4 phase">
                  <h3>Phase 3</h3>
                  <ul class="no-bullets">
                    <li><i class="uil uil-hourglass"></i> NFT Staking & Marketplace Launch</li>
                    <li><i class="uil uil-hourglass"></i> Hire Artist to become Ambassador in Long NFT Marketplace</li>
                    <li><i class="uil uil-hourglass"></i> Listing to Exchangers</li>
                    <li><i class="uil uil-hourglass"></i> New Roadmap Launch</li>
                    <li><i class="uil uil-hourglass"></i> Join the BTA chain network ,Governance Farming , Staking and other programs</li>
                    <li><i class="uil uil-hourglass"></i> Multi Chain NFT Launch</li>
                    <li><i class="uil uil-hourglass"></i> Multi Chain Dapp’s launch</li>
                    <li><i class="uil uil-hourglass"></i> Launchpad Launch</li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import Contract from "../config/contract";
import Web3 from "web3";
import apiService from "../services/ApiService";
import BigNumber from "bignumber.js";
import numeral from 'numeral';
function initialState() {
    return {
        hover: false,
        hover1a: false,
        hover2: false,
        hover2a: false,
        hover3: false,
        hover3a: false,
        hover4: false,
        hover4a: false,
        hover5: false,
        hover5a: false,
        modalConnectWallet: false,
        modalLogoutWallet: false,
        modalROILONGBNB: false,
        modalROIBTABNB: false,
        modalROILONGBTA: false,
        modalROILONG: false,
        modalROIBTA: false,
        modalAddFarmLONGBNB : false,
        modalCutFarmLONGBNB : false,
        modalAddFarmBTABNB : false,
        modalCutFarmBTABNB : false,
        modalAddFarmLONGBTA : false,
        modalCutFarmLONGBTA : false,
        modalAddFarmLONG : false,
        modalCutFarmLONG : false,
        modalAddFarmBTA : false,
        modalCutFarmBTA : false,
        show: false,
        walletConnect: {
            web3: null,
            MetaMaskId: "1", // main net netID
            netID: null, // user metamask id
            MetaMaskAddress: "", // user Address
            Web3Interval: null,
            AccountInterval: null,
            NetworkInterval: null,
        },
        info: {
            yourLongBalance: 0,
            totalSupply: 500000000,
            maxSupply: 500000000,
            longPrice: 0,
            btaPrice: 0,
            bnbPrice: 0,
            volume24hr: 0,
            longMarketCap: 0,
            TVL: 0,
            aprLongBnb: 0,
            aprBtaBnb: 0,
            aprLongBta: 0,
            aprLong: 0,
            aprBta: 0,
            liquidityLONGBNB: 0,
            liquidityBTABNB: 0,
            liquidityLONGBTA: 0,
            liquidityLONG: 0,
            liquidityBTA: 0,
        },
        farms: {
            longbnb: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            btabnb: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            longbta: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            long: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            bta: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
        },
        ROI: {
            longbnb: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            btabnb: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            longbta: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            long: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            bta: {
                d1: 0, d7: 0, d30: 0, d365: 0
            }
        }
    }
}
export default {
    name: 'index',
    components: {
        // VueMetamask,
        Footer,
        Header
    },
    data() {
        return initialState()
    },
    methods: {
        calculateAPYLONGBNB() {
            var self = this;
            // LONG BNB
            var  LpTokenContract1 = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbnb.abi, Contract.farms.lpToken.longbnb.contract);
            LpTokenContract1.methods.balanceOf(Contract.farms.contract).call({})
            .then(function(lpBalance){
                // get total supply of lp
                LpTokenContract1.methods.totalSupply().call({})
                .then(function(totalSupply){
                    // LpTokenContract.methods.getReserves().call({})
                    LpTokenContract1.methods.getReserves().call({})
                    .then(function(reserves){
                        var assetA = (lpBalance / totalSupply) * reserves._reserve0; // bta
                        var assetB = (lpBalance / totalSupply) * reserves._reserve1; // bnb
                        var totalAssetA = self.helperAmountHex(1, assetA);
                        var totalAssetB = self.helperAmountHex(1, assetB);
                        var totalCapTokenA = 0, totalCapTokenB = 0;

                        apiService.longPrice().then(res => {
                            var price = parseFloat(res.data.priceUSD);
                            var longPrice = price;
                            apiService.bnbPrice().then(res => {
                                var bnbPrice = parseFloat(res.data.priceUSD);
                                totalCapTokenA = totalAssetA * bnbPrice;
                                totalCapTokenB = totalAssetB * longPrice;
                                var totalAllCap = totalCapTokenA + totalCapTokenB;
                                self.info.liquidityLONGBNB = totalAllCap.toFixed(6);

                                self.info.TVL = self.info.TVL + totalAllCap;
                                var calculateAPYLONGBNB = (5*28800*365*100*longPrice) / totalAllCap;
                                if(calculateAPYLONGBNB != Infinity){
                                    self.info.aprLongBnb = calculateAPYLONGBNB;

                                    self.ROI.longbnb.d1 = self.info.aprLongBnb / 365;
                                    self.ROI.longbnb.d7 = (self.info.aprLongBnb / 365) * 7;
                                    self.ROI.longbnb.d30 = (self.info.aprLongBnb / 365) * 30;
                                    self.ROI.longbnb.d365 = self.info.aprLongBnb;
                                }
                            })

                        })
                    })
                })
            });
        },
        calculateAPYBTABNB() {
            // BTA BNB
            var self = this;
            var LpTokenContract2 = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.btabnb.abi, Contract.farms.lpToken.btabnb.contract);
            LpTokenContract2.methods.balanceOf(Contract.farms.contract).call({})
            .then(function(lpBalance){
                // get total supply of lp
                LpTokenContract2.methods.totalSupply().call({})
                .then(function(totalSupply){
                    // LpTokenContract2.methods.getReserves().call({})
                    LpTokenContract2.methods.getReserves().call({})
                    .then(function(reserves){
                        var assetA = (lpBalance / totalSupply) * reserves._reserve1; // bnb
                        var assetB = (lpBalance / totalSupply) * reserves._reserve0; // bta
                        var totalAssetA = self.helperAmountHex(1, assetA);
                        var totalAssetB = self.helperAmountHex(1, assetB);
                        var totalCapTokenA = 0, totalCapTokenB = 0;

                        apiService.longPrice().then(res => {
                            var longPrice = parseFloat(res.data.priceUSD);
                            apiService.bnbPrice().then(res2 => {
                                var bnbPrice = parseFloat(res2.data.priceUSD);
                                apiService.btaPrice().then(res3 => {
                                    var btaPrice = parseFloat(res3.data.priceUSD);
                                    totalCapTokenA = totalAssetA * bnbPrice;
                                    totalCapTokenB = totalAssetB * btaPrice;
                                    var totalAllCap = totalCapTokenA + totalCapTokenB;
                                    self.info.liquidityBTABNB = totalAllCap.toFixed(6);
                                    self.info.TVL = self.info.TVL + totalAllCap;
                                    var calculateAPYBTABNB = (5*28800*365*100*longPrice) / totalAllCap;
                                    if(calculateAPYBTABNB != Infinity){
                                        self.info.aprBtaBnb = calculateAPYBTABNB;

                                        self.ROI.btabnb.d1 = self.info.aprBtaBnb / 365;
                                        self.ROI.btabnb.d7 = (self.info.aprBtaBnb / 365) * 7;
                                        self.ROI.btabnb.d30 = (self.info.aprBtaBnb / 365) * 30;
                                        self.ROI.btabnb.d365 = self.info.aprBtaBnb;
                                    }
                                });
                            })
                        })
                    })
                })
            });
        },
        calculateAPYLONGBTA() {
            // LONG BTA
            var self = this;
            var LpTokenContract3 = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbta.abi, Contract.farms.lpToken.longbta.contract);
            LpTokenContract3.methods.balanceOf(Contract.farms.contract).call({})
            .then(function(lpBalance){
                // get total supply of lp
                LpTokenContract3.methods.totalSupply().call({})
                .then(function(totalSupply){
                    // LpTokenContract3.methods.getReserves().call({})
                    LpTokenContract3.methods.getReserves().call({})
                    .then(function(reserves){
                        // 0 bta 1 long
                        var assetA = (lpBalance / totalSupply) * reserves._reserve0; // bta
                        var assetB = (lpBalance / totalSupply) * reserves._reserve1; // long
                        var totalAssetA = self.helperAmountHex(1, assetA);
                        var totalAssetB = self.helperAmountHex(1, assetB);
                        var totalCapTokenA = 0, totalCapTokenB = 0;

                        apiService.longPrice().then(res => {
                            var longPrice = parseFloat(res.data.priceUSD);
                            apiService.btaPrice().then(res3 => {
                                var btaPrice = parseFloat(res3.data.priceUSD);
                                totalCapTokenA = totalAssetA * btaPrice;
                                totalCapTokenB = totalAssetB * longPrice;
                                var totalAllCap = totalCapTokenA + totalCapTokenB;
                                self.info.liquidityLONGBTA = totalAllCap.toFixed(6);
                                self.info.TVL = self.info.TVL + totalAllCap;
                                var calculateAPYLONGBTA = (2.5*28800*365*100*longPrice) / totalAllCap;
                                if(calculateAPYLONGBTA != Infinity){
                                    self.info.aprLongBta = calculateAPYLONGBTA;

                                    self.ROI.longbta.d1 = self.info.aprLongBta / 365;
                                    self.ROI.longbta.d7 = (self.info.aprLongBta / 365) * 7;
                                    self.ROI.longbta.d30 = (self.info.aprLongBta / 365) * 30;
                                    self.ROI.longbta.d365 = self.info.aprLongBta;
                                }
                            });
                        })
                    })
                })
            });
        },
        calculateAPYLONG() {
            var self = this;
            var LpTokenContract4 = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            LpTokenContract4.methods.poolInfo(Contract.farms.lpToken.long.pid).call({})
            .then(function(poolInfo){
                var lpSupply = self.helperAmountHex(1, poolInfo.lpSupply);
                apiService.longPrice().then(res => {
                    var longPrice = parseFloat(res.data.priceUSD);
                    var totalAllCap = lpSupply * longPrice;
                    var calculateAPYLONG = (0.8*28800*365*100*longPrice) / totalAllCap;
                    self.info.liquidityLONG = totalAllCap.toFixed(6);
                    self.info.TVL = self.info.TVL + totalAllCap;
                    if(calculateAPYLONG != Infinity){
                        self.info.aprLong = calculateAPYLONG;

                        self.ROI.long.d1 = self.info.aprLong / 365;
                        self.ROI.long.d7 = (self.info.aprLong / 365) * 7;
                        self.ROI.long.d30 = (self.info.aprLong / 365) * 30;
                        self.ROI.long.d365 = self.info.aprLong;
                    }
                });
            });
        },
        calculateAPYBTA() {
            var self = this;
            var LpTokenContract4 = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            LpTokenContract4.methods.poolInfo(Contract.farms.lpToken.bta.pid).call({})
            .then(function(poolInfo){
                var lpSupply = self.helperAmountHex(1, poolInfo.lpSupply);
                apiService.longPrice().then(res => {
                    var longPrice = parseFloat(res.data.priceUSD);
                    apiService.btaPrice().then(res2 => {
                        var btaPrice = parseFloat(res2.data.priceUSD);
                        var totalAllCap = lpSupply * btaPrice;
                        var calculateAPYBTA = (0.8*28800*365*100*longPrice) / totalAllCap;
                        self.info.liquidityBTA = totalAllCap.toFixed(6);
                        self.info.TVL = self.info.TVL + totalAllCap;
                        if(calculateAPYBTA != Infinity){
                            self.info.aprBta = calculateAPYBTA;

                            self.ROI.bta.d1 = self.info.aprBta / 365;
                            self.ROI.bta.d7 = (self.info.aprBta / 365) * 7;
                            self.ROI.bta.d30 = (self.info.aprBta / 365) * 30;
                            self.ROI.bta.d365 = self.info.aprBta;
                        }
                    });
                });
            });
        },
        helperAmountHex(type, amount) {
            if(type == 1){
                // human
                var n = new BigNumber (amount / 1e18);
                return n.toFixed(4);
            }
            else{
                // robot
                var x = new BigNumber (amount * 1e18);
                return x.toFixed();
            }
        },
        helperNumeral(amount, format = null){
            return numeral(amount).format(format);
        },
    },
    mounted() {
        // this.intervalTime()
        this.walletConnect.web3 = new Web3(new Web3.providers.HttpProvider(Contract.provider));
        this.calculateAPYLONGBNB();
        this.calculateAPYBTABNB();
        this.calculateAPYLONGBTA();
        this.calculateAPYLONG();
        this.calculateAPYBTA();
    },
    created() {
        document.title = 'Long Asset';
    },
}
</script>
