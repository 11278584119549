<template>
    <div>
        <Header></Header>
        <section id="page__balance" class="section">
            <div class="container">
                <div class="row align-items-center animate__animated animate__fadeInUp">
                    <div class="col-xl-12 mb-5 text-end">
                        <span class="btn btn-roki ms-3 me-2 text-right mt-2"><img src="../assets/img/binance.png" alt="" width="20px" class="icon"> <span class="namewallet">BSC Chain</span></span>
                        <a type="button" class="btn btn-todo mt-2 address-btn" @click="openModal('connectwallet')" v-if="walletConnect.web3 == null">
                            <i class="uil uil-wallet"></i> {{ walletConnect.MetaMaskAddress }}
                        </a>
                        <a type="button" class="btn btn-todo mt-2 address-btn" @click="openModal('logoutwallet')" v-else>
                            <i class="uil uil-wallet"></i> {{ helperAddress(walletConnect.MetaMaskAddress) }}
                        </a>
                    </div>
                    <div class="col-xl-12">
                        <div class="d-lg-flex flex-row plat text-white">
                            <div class="p-3 col">
                                <small>Your LONG Balance</small>
                                <h5 class="green"><b>{{ info.yourLongBalance }}</b></h5>
                                <small class="mt-4 d-block">LONG in Circulation</small>
                                <h5 class="green"><b>275,000,000</b></h5>
                            </div>
                            <div class="p-3 col">
                                <small>LONG Price</small>
                                <h5 class="green"><b>${{ info.longPrice }}</b></h5>
                                <small class="mt-4 d-block">Total Burned</small>
                                <h5 class="green"><b>100,000,000</b></h5>
                            </div>
                            <div class="p-3 col">
                                <small>Volume (24hr)</small>
                                <h5 class="green"><b>${{ helperNumeral(info.volume24hr, '0,0') }}</b></h5>
                                <small class="mt-4 d-block">Maximum Supply</small>
                                <h5 class="green"><b>{{ helperNumeral(info.maxSupply, '0,0') }}</b></h5>
                            </div>
                            <div class="p-3 col">
                                <small>LONG Market Cap</small>
                                <h5 class="green"><b>${{ helperNumeral(info.longMarketCap, '0,0') }}</b></h5>
                                <small class="mt-4 d-block">Total Value Locked (TVL)</small>
                                <h5 class="green"><b>${{ helperNumeral(info.TVL, '0,0') }}</b></h5>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-4">
                        <div class="d-flex flex-row plat text-white">
                            <div class="p-3 col">
                            <small>Your Long Balance</small>
                            <h5><b>Locked</b></h5>

                            <small class="mt-4 d-block">Long in circulation</small>
                            <h5><b>140,636,015</b></h5>
                            </div>
                            <div class="p-3 col">
                            <small>Your Long Balance</small>
                            <h5><b>Locked</b></h5>

                            <small class="mt-4 d-block">Long in circulation</small>
                            <h5><b>140,636,015</b></h5>
                            </div>
                        </div>
                        </div> -->
                </div>
            </div>
        </section>
        <section id="page__panel" class="animate__animated animate__fadeInUp">
            <div class="container">
                <h1 class="ultra-text gold">Farm & Stake</h1>
                <h6 class="text-white">Farm & Stake Liquidity Pool (LP) tokens to earn LONG.</h6>
                <div class="d-flex flex-row text-center mt-5">
                    <a href="#" class="btn p-2 col btn-blue mx-5"><i class="uil uil-hourglass"></i> Ongoing</a>
                    <a href="#" class="btn p-2 col btn-green mx-5"><i class="uil uil-check-circle"></i> Finished</a>
                </div>
                <!-- Long - BNB  -->
                <div class="row mt-5">
                    <div class="accordion" id="accordfive">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headfive">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collfive" aria-expanded="true" aria-controls="collfive">
                                    <div class="d-lg-flex flex-row w-100" style="align-items:center;">
                                        <div class="p-2 col-xl-4">
                                            <h6 class="min-box">Farm</h6>
                                            <h5 class="gold">LONG - BNB</h5>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Earned</h6>
                                            <p class="green med-text"><b>{{ farms.longbnb.model.longEarned != 0 ? farms.longbnb.model.longEarned : '?' }}</b></p>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">APY</h6>
                                            <p class="green med-text"><b>{{ helperNumeral(info.aprLongBnb, '0,0') }}%</b></p>
                                        </div>
                                        <div class="col text-start">
                                            <a href="#" @click="openModal('modalROILONGBNB')" aria-expanded="false" class="caltrig"><i class="uil uil-calculator-alt med-text gold"></i></a>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Liquidity</h6>
                                            <p class="green med-text"><b>${{ parseFloat(info.liquidityLONGBNB).toFixed(0) }}</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }"></i>
                                            <div class="q-message qm1" v-if="hover">
                                              <p>The total value of the funds in this farm's liquidity pool</p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Multiplier</h6>
                                            <p class="green med-text"><b>5x</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover1a = true" @mouseleave="hover1a = false" :class="{ active: hover1a }"></i>
                                            <div class="q-message qm2" v-if="hover1a">
                                              <p>
                                              The multiplier represents the amount of LONG rewards each farm gets.</p>
                                              <p>
                                              For example, if a 1x farm was getting 1 LONG per block, a 5x farm would be getting 5 LONG per block.
                                              </p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="blue text-end"><b>Details</b></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collfive" class="accordion-collapse collapse" aria-labelledby="headfour" data-bs-parent="#accordfour">
                                <div class="accordion-body">
                                    <div class="d-lg-flex flex-row">
                                        <div class="p-2 col">
                                            <a href="https://exchange.pancakeswap.finance/#/add/0xd6004836cb07D063f2cB498C25e351CC93194079/BNB" target="_blank" class="green d-block"><b>Get LONG-BNB LP <i class="uil uil-external-link-alt"></i></b></a>
                                            <a href="https://bscscan.com/address/0xde03a12BB7D8B456967073eC809Bb4fe07589B65" target="_blank" class="green d-block"><b>View Contract <i class="uil uil-external-link-alt"></i></b></a>
                                            <a href="https://pancakeswap.info/pair/0xd6004836cb07d063f2cb498c25e351cc93194079" class="green d-block" target="_blank" ><b>See Pair Info <i class="uil uil-external-link-alt"></i></b></a>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block">
                                                <h6 class="gold">Long EARNED</h6>
                                                <h5 class="green">{{ farms.longbnb.model.longEarned }}</h5>
                                                <small>~{{ farms.longbnb.model.longEarnedUsd != 0 ? parseFloat(farms.longbnb.model.longEarnedUsd).toFixed(2) : '0.00' }}USD</small>
                                                <button class="btn-harvest" :disabled="farms.longbnb.btn.harvest == false" @click="harvestFarm('longbnb')">Harvest</button>
                                            </div>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block" v-if="farms.longbnb.btn.approval == false">
                                                <h6 class="gold">ENABLE FARM</h6>
                                                <div v-if="farms.longbnb.btn.approval_load == false">
                                                    <button @click="enableFarms('longbnb')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.longbnb.btn.approval_text }}</button>
                                                </div>
                                                <div v-if="farms.longbnb.btn.approval_load == true">
                                                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                                                </div>
                                            </div>
                                            <div class="plat p-3 text-white block" v-else>
                                                <h6 class="gold">LONG-BNB LP STAKED</h6>
                                                <div class="d-flex flex-row align-items-center">
                                                    <div v-if="farms.longbnb.model.totalStaked > 0" @click="openModalCutFarm('longbnb')" class="min-btn col-xl-3 col-4"> - </div>
                                                    <div v-if="farms.longbnb.model.totalStaked <= 0" class="min-btn disabled col-xl-3 col-4"> - </div>
                                                    <div class="col-xl col-4">{{ farms.longbnb.model.totalStaked }}</div>
                                                    <div @click="openModalAddFarm('longbnb')" class="plus-btn col-xl-3 col-4"> + </div>
                                                </div>
                                                <!-- <input type="text" class="form-control" v-model="farms.longbnb.model"> -->
                                                <!-- <button :disabled="farms.longbnb.btn.approval == true" @click="enableFarms('longbnb')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.longbnb.btn.approval_text }}</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BTA - BNB  -->
                <div class="row mt-3">
                    <div class="accordion" id="accordfive">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headfive">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collfour" aria-expanded="true" aria-controls="collfour">
                                    <div class="d-lg-flex flex-row w-100" style="align-items:center;">
                                        <div class="p-2 col-xl-4">
                                            <h6 class="min-box">Farm</h6>
                                            <h5 class="gold">BTA - BNB</h5>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Earned</h6>
                                            <p class="green med-text"><b>{{ farms.btabnb.model.longEarned != 0 ? farms.btabnb.model.longEarned : '?' }}</b></p>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">APY</h6>
                                            <p class="green med-text"><b>{{ helperNumeral(info.aprBtaBnb, '0,0') }}%</b></p>
                                        </div>
                                        <div class="col text-start">
                                            <a href="#" @click="openModal('modalROIBTABNB')" aria-expanded="false" class="caltrig"><i class="uil uil-calculator-alt med-text gold"></i></a>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Liquidity</h6>
                                            <p class="green med-text"><b>${{ parseFloat(info.liquidityBTABNB).toFixed(0) }}</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover2 = true" @mouseleave="hover2 = false" :class="{ active: hover2 }"></i>
                                            <div class="q-message qm1" v-if="hover2">
                                                <p>The total value of the funds in this farm's liquidity pool</p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Multiplier</h6>
                                            <p class="green med-text"><b>5x</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover2a = true" @mouseleave="hover2a = false" :class="{ active: hover2a }"></i>
                                            <div class="q-message qm2" v-if="hover2a">
                                                <p>
                                                The multiplier represents the amount of LONG rewards each farm gets.</p>
                                                <p>
                                                For example, if a 1x farm was getting 1 LONG per block, a 5x farm would be getting 5 LONG per block.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="blue text-end"><b>Details</b></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collfour" class="accordion-collapse collapse" aria-labelledby="headfour" data-bs-parent="#accordfour">
                                <div class="accordion-body">
                                    <div class="d-lg-flex flex-row">
                                        <div class="p-2 col">
                                            <a href="https://exchange.pancakeswap.finance/#/add/0xAd9787017e82f6368BbE4893b475CaadA2258564/BNB" target="_blank" class="green d-block"><b>Get BTA-BNB LP <i class="uil uil-external-link-alt"></i></b></a>
                                            <a href="https://bscscan.com/address/0xde03a12BB7D8B456967073eC809Bb4fe07589B65" target="_blank" class="green d-block"><b>View Contract <i class="uil uil-external-link-alt"></i></b></a>
                                            <a href="https://pancakeswap.info/pair/0xad9787017e82f6368bbe4893b475caada2258564" class="green d-block"><b>See Pair Info <i class="uil uil-external-link-alt"></i></b></a>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block">
                                                <h6 class="gold">Long EARNED</h6>
                                                <h5 class="green">{{ farms.btabnb.model.longEarned }}</h5>
                                                <small>~{{ farms.btabnb.model.longEarnedUsd != 0 ? parseFloat(farms.btabnb.model.longEarnedUsd).toFixed(2) : '0.00' }}USD</small>
                                                <button class="btn-harvest" :disabled="farms.btabnb.btn.harvest == false" @click="harvestFarm('btabnb')">Harvest</button>
                                            </div>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block" v-if="farms.btabnb.btn.approval == false">
                                                <h6 class="gold">ENABLE FARM</h6>
                                                <div v-if="farms.btabnb.btn.approval_load == false">
                                                    <button @click="enableFarms('btabnb')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.btabnb.btn.approval_text }}</button>
                                                </div>
                                                <div v-if="farms.btabnb.btn.approval_load == true">
                                                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                                                </div>
                                            </div>
                                            <div class="plat p-3 text-white block" v-else>
                                                <h6 class="gold">BTA-BNB LP STAKED</h6>
                                                <div class="d-flex flex-row align-items-center">
                                                    <div v-if="farms.btabnb.model.totalStaked > 0" @click="openModalCutFarm('btabnb')" class="min-btn col-xl-3 col-4"> - </div>
                                                    <div v-if="farms.btabnb.model.totalStaked <= 0" class="min-btn disabled col-xl-3 col-4"> - </div>
                                                    <div class="col-xl col-4">{{ farms.btabnb.model.totalStaked }}</div>
                                                    <div @click="openModalAddFarm('btabnb')" class="plus-btn col-xl-3 col-4"> + </div>
                                                </div>
                                                <!-- <input type="text" class="form-control" v-model="farms.btabnb.model"> -->
                                                <!-- <button :disabled="farms.btabnb.btn.approval == true" @click="enableFarms('btabnb')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.btabnb.btn.approval_text }}</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- LONG - BTA  -->
                <div class="row mt-3">
                    <div class="accordion" id="accordfive">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headfive">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collthree" aria-expanded="true" aria-controls="collthree">
                                    <div class="d-lg-flex flex-row w-100" style="align-items:center;">
                                        <div class="p-2 col-xl-4">
                                            <h6 class="min-box">Farm</h6>
                                            <h5 class="gold">LONG - BTA</h5>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Earned</h6>
                                            <p class="green med-text"><b>{{ farms.longbta.model.longEarned != 0 ? farms.longbta.model.longEarned : '?' }}</b></p>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">APY</h6>
                                            <p class="green med-text"><b>{{ helperNumeral(info.aprLongBta, '0,0') }}%</b></p>
                                        </div>
                                        <div class="col text-start">
                                            <a href="#" @click="openModal('modalROILONGBTA')" aria-expanded="false" class="caltrig"><i class="uil uil-calculator-alt med-text gold"></i></a>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Liquidity</h6>
                                            <p class="green med-text"><b>${{ parseFloat(info.liquidityLONGBTA).toFixed(0) }}</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover3 = true" @mouseleave="hover3 = false" :class="{ active: hover3 }"></i>
                                            <div class="q-message qm1" v-if="hover3">
                                                <p>The total value of the funds in this farm's liquidity pool</p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Multiplier</h6>
                                            <p class="green med-text"><b>2.5x</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover3a = true" @mouseleave="hover3a = false" :class="{ active: hover3a }"></i>
                                            <div class="q-message qm2" v-if="hover3a">
                                                <p>
                                                The multiplier represents the amount of LONG rewards each farm gets.</p>
                                                <p>
                                                For example, if a 1x farm was getting 1 LONG per block, a 2.5x farm would be getting 2.5 LONG per block.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="blue text-end"><b>Details</b></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collthree" class="accordion-collapse collapse" aria-labelledby="headfour" data-bs-parent="#accordfour">
                                <div class="accordion-body">
                                    <div class="d-lg-flex flex-row">
                                        <div class="p-2 col">
                                            <a target="_blank" href="https://exchange.pancakeswap.finance/#/add/0xAd9787017e82f6368BbE4893b475CaadA2258564/0xd6004836cb07D063f2cB498C25e351CC93194079" class="green d-block"><b>Get LONG-BTA LP <i class="uil uil-external-link-alt"></i></b></a>
                                            <a target="_blank" href="https://bscscan.com/address/0xde03a12BB7D8B456967073eC809Bb4fe07589B65" class="green d-block"><b>View Contract <i class="uil uil-external-link-alt"></i></b></a>
                                            <a target="_blank" href="https://pancakeswap.info/pair/0xad9787017e82f6368bbe4893b475caada2258564" class="green d-block"><b>See Pair Info <i class="uil uil-external-link-alt"></i></b></a>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block">
                                                <h6 class="gold">Long EARNED</h6>
                                                <h5 class="green">{{ farms.longbta.model.longEarned }}</h5>
                                                <small>~{{ farms.longbta.model.longEarnedUsd != 0 ? parseFloat(farms.longbta.model.longEarnedUsd).toFixed(2) : '0.00' }}USD</small>
                                                <button class="btn-harvest" :disabled="farms.longbta.btn.harvest == false" @click="harvestFarm('longbta')">Harvest</button>
                                            </div>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block" v-if="farms.longbta.btn.approval == false">
                                                <h6 class="gold">ENABLE FARM</h6>
                                                <div v-if="farms.longbta.btn.approval_load == false">
                                                    <button @click="enableFarms('longbta')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.longbta.btn.approval_text }}</button>
                                                </div>
                                                <div v-if="farms.longbta.btn.approval_load == true">
                                                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                                                </div>
                                            </div>
                                            <div class="plat p-3 text-white block" v-else>
                                                <h6 class="gold">LONG-BTA LP STAKED</h6>
                                                <div class="d-flex flex-row align-items-center">
                                                    <div v-if="farms.longbta.model.totalStaked > 0" @click="openModalCutFarm('longbta')" class="min-btn col-xl-3 col-4"> - </div>
                                                    <div v-if="farms.longbta.model.totalStaked <= 0" class="min-btn disabled col-xl-3 col-4"> - </div>
                                                    <div class="col-xl col-4">{{ farms.longbta.model.totalStaked }}</div>
                                                    <div @click="openModalAddFarm('longbta')" class="plus-btn col-xl-3 col-4"> + </div>
                                                </div>
                                                <!-- <input type="text" class="form-control" v-model="farms.longbta.model"> -->
                                                <!-- <button :disabled="farms.longbta.btn.approval == true" @click="enableFarms('longbta')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.longbta.btn.approval_text }}</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- LONG  -->
                <div class="row mt-3">
                    <div class="accordion" id="accordfive">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headfive">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#colltwo" aria-expanded="true" aria-controls="colltwo">
                                    <div class="d-lg-flex flex-row w-100" style="align-items:center;">
                                        <div class="p-2 col-xl-4">
                                            <h6 class="min-box">Stake</h6>
                                            <h5 class="gold">LONG</h5>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Earned</h6>
                                            <p class="green med-text"><b>{{ farms.long.model.longEarned != 0 ? farms.long.model.longEarned : '?' }}</b></p>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">APY</h6>
                                            <p class="green med-text"><b>{{ helperNumeral(info.aprLong, '0,0') }}%</b></p>
                                        </div>
                                        <div class="col text-start">
                                            <a href="#" @click="openModal('modalROILONG')" aria-expanded="false" class="caltrig"><i class="uil uil-calculator-alt med-text gold"></i></a>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Liquidity</h6>
                                            <p class="green med-text"><b>${{ parseFloat(info.liquidityLONG).toFixed(0) }}</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover4 = true" @mouseleave="hover4 = false" :class="{ active: hover4 }"></i>
                                            <div class="q-message qm1" v-if="hover4">
                                              <p>The total value of the funds in this farm's liquidity pool</p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Multiplier</h6>
                                            <p class="green med-text"><b>0.8x</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover4a = true" @mouseleave="hover4a = false" :class="{ active: hover4a }"></i>
                                            <div class="q-message qm2" v-if="hover4a">
                                                <p>
                                                The multiplier represents the amount of LONG rewards each farm gets.</p>
                                                <p>
                                                For example, if a 1x farm was getting 1 LONG per block, a 0.8x farm would be getting 0.8 LONG per block.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="blue text-end"><b>Details</b></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="colltwo" class="accordion-collapse collapse" aria-labelledby="headfour" data-bs-parent="#accordfour">
                                <div class="accordion-body">
                                    <div class="d-lg-flex flex-row">
                                        <div class="p-2 col">
                                            <a target="_blank" href="https://bscscan.com/address/0xde03a12BB7D8B456967073eC809Bb4fe07589B65" class="green d-block"><b>View Contract <i class="uil uil-external-link-alt"></i></b></a>
                                            <a target="_blank" href="https://pancakeswap.info/pair/0xd6004836cb07d063f2cb498c25e351cc93194079" class="green d-block"><b>See Pair Info <i class="uil uil-external-link-alt"></i></b></a>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block">
                                                <h6 class="gold">Long EARNED</h6>
                                                <h5 class="green">{{ farms.long.model.longEarned }}</h5>
                                                <small>~{{ farms.long.model.longEarnedUsd != 0 ? parseFloat(farms.long.model.longEarnedUsd).toFixed(2) : '0.00' }}USD</small>
                                                <button class="btn-harvest" :disabled="farms.long.btn.harvest == false" @click="harvestFarm('long')">Harvest</button>
                                            </div>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block" v-if="farms.long.btn.approval == false">
                                                <h6 class="gold">ENABLE FARM</h6>
                                                <div v-if="farms.long.btn.approval_load == false">
                                                    <button @click="enableFarms('long')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.long.btn.approval_text }}</button>
                                                </div>
                                                <div v-if="farms.long.btn.approval_load == true">
                                                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                                                </div>
                                            </div>
                                            <div class="plat p-3 text-white block" v-else>
                                                <h6 class="gold">LONG STAKED</h6>
                                                <div class="d-flex flex-row align-items-center">
                                                    <div v-if="farms.long.model.totalStaked > 0" @click="openModalCutFarm('long')" class="min-btn col-xl-3 col-4"> - </div>
                                                    <div v-if="farms.long.model.totalStaked <= 0" class="min-btn disabled col-xl-3 col-4"> - </div>
                                                    <div class="col-xl col-4">{{ farms.long.model.totalStaked }}</div>
                                                    <div @click="openModalAddFarm('long')" class="plus-btn col-xl-3 col-4"> + </div>
                                                </div>
                                                <!-- <input type="text" class="form-control" v-model="farms.long.model"> -->
                                                <!-- <button :disabled="farms.long.btn.approval == true" @click="enableFarms('long')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.long.btn.approval_text }}</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BTA  -->
                <div class="row mt-3">
                    <div class="accordion" id="accordfive">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headfive">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collone" aria-expanded="true" aria-controls="collone">
                                    <div class="d-lg-flex flex-row w-100" style="align-items:center;">
                                        <div class="p-2 col-xl-4">
                                            <h6 class="min-box">Stake</h6>
                                            <h5 class="gold">BTA</h5>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Earned</h6>
                                            <p class="green med-text"><b>{{ farms.bta.model.longEarned != 0 ? farms.bta.model.longEarned : '?' }}</b></p>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">APY</h6>
                                            <p class="green med-text"><b>{{ helperNumeral(info.aprBta, '0,0') }}%</b></p>
                                        </div>
                                        <div class="col text-start">
                                            <a href="#" @click="openModal('modalROIBTA')" aria-expanded="false" class="caltrig"><i class="uil uil-calculator-alt med-text gold"></i></a>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Liquidity</h6>
                                            <p class="green med-text"><b>${{ parseFloat(info.liquidityBTA).toFixed(0) }}</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover5 = true" @mouseleave="hover5 = false" :class="{ active: hover5 }"></i>
                                            <div class="q-message qm1" v-if="hover5">
                                              <p>The total value of the funds in this farm's liquidity pool</p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="gold">Multiplier</h6>
                                            <p class="green med-text"><b>0.8x</b></p>
                                        </div>
                                        <div class="col q-wrap">
                                            <i class="uil uil-question-circle q-mark q1" @mouseover="hover5a = true" @mouseleave="hover5a = false" :class="{ active: hover5a }"></i>
                                            <div class="q-message qm2" v-if="hover5a">
                                                <p>
                                                The multiplier represents the amount of LONG rewards each farm gets.</p>
                                                <p>
                                                For example, if a 1x farm was getting 1 LONG per block, a 0.8x farm would be getting 0.8 LONG per block.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="p-2 col">
                                            <h6 class="blue text-end"><b>Details</b></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collone" class="accordion-collapse collapse" aria-labelledby="headfour" data-bs-parent="#accordfour">
                                <div class="accordion-body">
                                    <div class="d-lg-flex flex-row">
                                        <div class="p-2 col">
                                            <a target="_blank" href="https://bscscan.com/address/0xde03a12BB7D8B456967073eC809Bb4fe07589B65" class="green d-block"><b>View Contract <i class="uil uil-external-link-alt"></i></b></a>
                                            <a target="_blank" href="https://pancakeswap.info/pair/0xad9787017e82f6368bbe4893b475caada2258564" class="green d-block"><b>See Pair Info <i class="uil uil-external-link-alt"></i></b></a>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block">
                                                <h6 class="gold">Long EARNED</h6>
                                                <h5 class="green">{{ farms.bta.model.longEarned }}</h5>
                                                <small>~{{ farms.bta.model.longEarnedUsd != 0 ? parseFloat(farms.bta.model.longEarnedUsd).toFixed(2) : '0.00' }}USD</small>
                                                <button class="btn-harvest" :disabled="farms.bta.btn.harvest == false" @click="harvestFarm('bta')">Harvest</button>
                                            </div>
                                        </div>
                                        <div class="p-2 col-xl-5">
                                            <div class="plat p-3 text-white block" v-if="farms.bta.btn.approval == false">
                                                <h6 class="gold">ENABLE FARM</h6>
                                                <div v-if="farms.bta.btn.approval_load == false">
                                                    <button @click="enableFarms('bta')" class="btn-enable mt-3 w-100 d-block text-center">{{ farms.bta.btn.approval_text }}</button>
                                                </div>
                                                <div v-if="farms.bta.btn.approval_load == true">
                                                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                                                </div>
                                            </div>
                                            <div class="plat p-3 text-white block" v-else>
                                                <h6 class="gold">BTA STAKED</h6>
                                                <div class="d-flex flex-row align-items-center">
                                                    <div v-if="farms.bta.model.totalStaked > 0" @click="openModalCutFarm('bta')" class="min-btn col-xl-3 col-4"> - </div>
                                                    <div v-if="farms.bta.model.totalStaked <= 0" class="min-btn disabled col-xl-3 col-4"> - </div>
                                                    <div class="col-xl col-4">{{ farms.bta.model.totalStaked }}</div>
                                                    <div @click="openModalAddFarm('bta')" class="plus-btn col-xl-3 col-4"> + </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer></Footer>

        <!-- Modal Wallet Connect-->
        <div class="modal fade" ref="modal" id="modalConnectWallet" :class="{show, 'd-block': modalConnectWallet}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('connectwallet')">x</button>
                        <h1 class="mb-3">Connect Wallet</h1>
                        <div class="d-flex flex-row my-4">
                            <div class="p-2">
                                <span class="bullet me-3">1</span>
                            </div>
                            <div class="p-2">
                                <p class="med-text">Accept <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a></p>
                                <div class="form-check">
                                    <input class="form-check-input d-block" type="checkbox" checked value="" id="accept" >
                                    <label class="form-check-label d-flex" for="accept">
                                    I read and accept
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row my-4">
                            <div class="p-2">
                                <span class="bullet me-3">2</span>
                            </div>
                            <div class="p-2">
                                <p class="med-text text-start">Choose Network</p>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cn1" disabled>
                                    <label class="form-check-label cb" for="cn1">
                                    <img src="../assets/img/bta.png" alt="" class="d-block">
                                    </label>
                                    <p class="text-center mt-2 d-block">BTA Chain</p>
                                </div>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cn2">
                                    <label class="form-check-label cb" for="cn2">
                                    <img src="../assets/img/binance.png" alt="" class="d-block">
                                    </label>
                                    <p class="text-center mt-2 d-block">BSC Chain</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row my-4">
                            <div class="p-2">
                                <span class="bullet me-3">3</span>
                            </div>
                            <div class="p-2">
                                <p class="med-text text-start">Choose Wallet</p>
                                <div class="form-check form-check-inline p-1">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw1">
                                    <label class="form-check-label cb" for="cw1" @click="connectWallet('metamask')">
                                    <img src="../assets/img/metamask.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Metamask</p>
                                </div>
                                <div class="form-check form-check-inline p-1 d-none">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw2" disabled>
                                    <label class="form-check-label cb" for="cw2">
                                    <img src="../assets/img/trust.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Trust Wallet</p>
                                </div>
                                <div class="form-check form-check-inline p-1 d-none">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw3" disabled>
                                    <label class="form-check-label cb" for="cw3">
                                    <img src="../assets/img/tp.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Token Pocket</p>
                                </div>
                                <div class="form-check form-check-inline p-1 d-none">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw4" disabled>
                                    <label class="form-check-label cb" for="cw4">
                                    <img src="../assets/img/math.png" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Math Wallet</p>
                                </div>
                                <div class="form-check form-check-inline p-1 d-none">
                                    <input class="form-check-input im" type="radio" name="flexRadioDefault" id="cw5" disabled>
                                    <label class="form-check-label cb" for="cw5">
                                    <img src="../assets/img/sf.jpg" alt="" width="80px">
                                    </label>
                                    <p class="text-center mt-2">Safefal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Logout Wallet Connect-->
        <div class="modal fade" ref="modal" id="modalLogoutWallet" :class="{show, 'd-block': modalLogoutWallet}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('logoutwallet')">x</button>
                        <h3 class="mb-4">Wallet</h3>
                        <h6 class="mb-4">{{ walletConnect.MetaMaskAddress }}</h6>
                        <button @click="logoutWeb3()" class="btn btn-warning btn-sm btn-block"><i class="uil uil-times-circle"></i>  <b>LOGOUT</b></button>
                        <br>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal ROI CALCULATOR-->
        <!-- LONG BNB  -->
        <div class="modal fade" ref="modal" id="modalROILONGBNB" :class="{show, 'd-block': modalROILONGBNB}" role="dialog">
            <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('modalROILONGBNB')">x</button>
                        <h1 class="mb-3">ROI</h1>
                        <hr>
                        <table class="table table-borderless text-white">
                            <tr>
                                <td class="gold">TIMEFRAME</td>
                                <td class="gold">ROI</td>
                            </tr>
                            <tr>
                                <td>1d</td>
                                <td>{{ parseFloat(ROI.longbnb.d1).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>7d</td>
                                <td>{{ parseFloat(ROI.longbnb.d7).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>30d</td>
                                <td>{{ parseFloat(ROI.longbnb.d30).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>365d(APY)</td>
                                <td>{{ parseFloat(ROI.longbnb.d365).toFixed(2) }} %</td>
                            </tr>
                        </table>
                        <small class="green my-4 d-block">Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</small>
                        <a href="https://exchange.pancakeswap.finance/#/add/0xd6004836cb07D063f2cB498C25e351CC93194079/BNB" target="_blank" class="mt-5 d-block gold text-center">Get LONG-BNB <i class="uil uil-external-link-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- BTA BNB  -->
        <div class="modal fade" ref="modal" id="modalROIBTABNB" :class="{show, 'd-block': modalROIBTABNB}" role="dialog">
            <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('modalROIBTABNB')">x</button>
                        <h1 class="mb-3">ROI</h1>
                        <hr>
                        <table class="table table-borderless text-white">
                            <tr>
                                <td class="gold">TIMEFRAME</td>
                                <td class="gold">ROI</td>
                            </tr>
                            <tr>
                                <td>1d</td>
                                <td>{{ parseFloat(ROI.btabnb.d1).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>7d</td>
                                <td>{{ parseFloat(ROI.btabnb.d7).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>30d</td>
                                <td>{{ parseFloat(ROI.btabnb.d30).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>365d(APY)</td>
                                <td>{{ parseFloat(ROI.btabnb.d365).toFixed(2) }} %</td>
                            </tr>
                        </table>
                        <small class="green my-4 d-block">Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</small>
                        <a href="https://exchange.pancakeswap.finance/#/add/0xAd9787017e82f6368BbE4893b475CaadA2258564/BNB" class="mt-5 d-block gold text-center" target="_blank">Get BTA-BNB <i class="uil uil-external-link-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- LONG BTA  -->
        <div class="modal fade" ref="modal" id="modalROILONGBTA" :class="{show, 'd-block': modalROILONGBTA}" role="dialog">
            <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('modalROILONGBTA')">x</button>
                        <h1 class="mb-3">ROI</h1>
                        <hr>
                        <table class="table table-borderless text-white">
                            <tr>
                                <td class="gold">TIMEFRAME</td>
                                <td class="gold">ROI</td>
                            </tr>
                            <tr>
                                <td>1d</td>
                                <td>{{ parseFloat(ROI.longbta.d1).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>7d</td>
                                <td>{{ parseFloat(ROI.longbta.d7).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>30d</td>
                                <td>{{ parseFloat(ROI.longbta.d30).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>365d(APY)</td>
                                <td>{{ parseFloat(ROI.longbta.d365).toFixed(2) }} %</td>
                            </tr>
                        </table>
                        <small class="green my-4 d-block">Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</small>
                        <a href="https://exchange.pancakeswap.finance/#/add/0xAd9787017e82f6368BbE4893b475CaadA2258564/0xd6004836cb07D063f2cB498C25e351CC93194079" class="mt-5 d-block gold text-center" target="_blank">Get LONG-BTA <i class="uil uil-external-link-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- LONG  -->
        <div class="modal fade" ref="modal" id="modalROILONG" :class="{show, 'd-block': modalROILONG}" role="dialog">
            <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('modalROILONG')">x</button>
                        <h1 class="mb-3">ROI</h1>
                        <hr>
                        <table class="table table-borderless text-white">
                            <tr>
                                <td class="gold">TIMEFRAME</td>
                                <td class="gold">ROI</td>
                            </tr>
                            <tr>
                                <td>1d</td>
                                <td>{{ parseFloat(ROI.long.d1).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>7d</td>
                                <td>{{ parseFloat(ROI.long.d7).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>30d</td>
                                <td>{{ parseFloat(ROI.long.d30).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>365d(APY)</td>
                                <td>{{ parseFloat(ROI.long.d365).toFixed(2) }} %</td>
                            </tr>
                        </table>
                        <small class="green my-4 d-block">Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</small>
                        <a href="https://exchange.pancakeswap.finance/#/swap" class="mt-5 d-block gold text-center" target="_blank">Get LONG <i class="uil uil-external-link-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- BTA  -->
        <div class="modal fade" ref="modal" id="modalROIBTA" :class="{show, 'd-block': modalROIBTA}" role="dialog">
            <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModal('modalROIBTA')">x</button>
                        <h1 class="mb-3">ROI</h1>
                        <hr>
                        <table class="table table-borderless text-white">
                            <tr>
                                <td class="gold">TIMEFRAME</td>
                                <td class="gold">ROI</td>
                            </tr>
                            <tr>
                                <td>1d</td>
                                <td>{{ parseFloat(ROI.bta.d1).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>7d</td>
                                <td>{{ parseFloat(ROI.bta.d7).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>30d</td>
                                <td>{{ parseFloat(ROI.bta.d30).toFixed(2) }} %</td>
                            </tr>
                            <tr>
                                <td>365d(APY)</td>
                                <td>{{ parseFloat(ROI.bta.d365).toFixed(2) }} %</td>
                            </tr>
                        </table>
                        <small class="green my-4 d-block">Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</small>
                        <a href="https://exchange.pancakeswap.finance/#/swap" class="mt-5 d-block gold text-center" target="_blank">Get BTA <i class="uil uil-external-link-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Add Farm Long BNB  -->
        <div class="modal fade" ref="modal" id="modalAddFarmLONGBNB" :class="{show, 'd-block': modalAddFarmLONGBNB}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalAddFarm('longbnb')">x</button>
                        <h3 class="mb-3">Stake LongBNB</h3>
                        <h5>Available: {{ farms.longbnb.model.availableBalance }}</h5>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.longbnb.model.formInputAdd">
                                <button v-if="farms.longbnb.btn.addModal == false" @click="setMaxStake('longbnb')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbnb.btn.addModal == false">
                            <div class="row px-3">
                                <button @click="openModalAddFarm('longbnb')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmAddFarm('longbnb')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbnb.btn.addModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Cut Farm Long BNB  -->
        <div class="modal fade" ref="modal" id="modalCutFarmLONGBNB" :class="{show, 'd-block': modalCutFarmLONGBNB}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalCutFarm('longbnb')">x</button>
                        <h3 class="mb-3">unstake Long-BNB</h3>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.longbnb.model.formInputCut">
                                <button v-if="farms.longbnb.btn.cutModal == false" @click="setMaxUnStake('longbnb')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbnb.btn.cutModal == false">
                            <div class="row px-3">
                                <button @click="openModalCutFarm('longbnb')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmCutFarm('longbnb')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbnb.btn.cutModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Add Farm BTA BNB  -->
        <div class="modal fade" ref="modal" id="modalAddFarmBTABNB" :class="{show, 'd-block': modalAddFarmBTABNB}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalAddFarm('btabnb')">x</button>
                        <h3 class="mb-3">Stake BTA-BNB</h3>
                        <h5>Available: {{ farms.btabnb.model.availableBalance }}</h5>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.btabnb.model.formInputAdd">
                                <button v-if="farms.btabnb.btn.addModal == false" @click="setMaxStake('btabnb')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.btabnb.btn.addModal == false">
                            <div class="row px-3">
                                <button @click="openModalAddFarm('btabnb')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmAddFarm('btabnb')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.btabnb.btn.addModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Cut Farm BTA BNB  -->
        <div class="modal fade" ref="modal" id="modalCutFarmBTABNB" :class="{show, 'd-block': modalCutFarmBTABNB}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalCutFarm('btabnb')">x</button>
                        <h3 class="mb-3">UnStake BTA-BNB</h3>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.btabnb.model.formInputCut">
                                <button v-if="farms.btabnb.btn.cutModal == false" @click="setMaxUnStake('btabnb')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.btabnb.btn.cutModal == false">
                            <div class="row px-3">
                                <button @click="openModalCutFarm('btabnb')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmCutFarm('btabnb')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.btabnb.btn.cutModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Add Farm LONG BTA  -->
        <div class="modal fade" ref="modal" id="modalAddFarmLONGBTA" :class="{show, 'd-block': modalAddFarmLONGBTA}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalAddFarm('longbta')">x</button>
                        <h3 class="mb-3">Stake LONG-BTA</h3>
                        <h5>Available: {{ farms.longbta.model.availableBalance }}</h5>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.longbta.model.formInputAdd">
                                <button v-if="farms.longbta.btn.addModal == false" @click="setMaxStake('longbta')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbta.btn.addModal == false">
                            <div class="row px-3">
                                <button @click="openModalAddFarm('longbta')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmAddFarm('longbta')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbta.btn.addModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Cut Farm LONG BTA  -->
        <div class="modal fade" ref="modal" id="modalCutFarmLONGBTA" :class="{show, 'd-block': modalCutFarmLONGBTA}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalCutFarm('longbta')">x</button>
                        <h3 class="mb-3">unstake LONG-BTA</h3>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.longbta.model.formInputCut">
                                <button v-if="farms.longbta.btn.cutModal == false" @click="setMaxUnStake('longbta')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbta.btn.cutModal == false">
                            <div class="row px-3">
                                <button @click="openModalCutFarm('longbta')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmCutFarm('longbta')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.longbta.btn.cutModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Add Farm LONG  -->
        <div class="modal fade" ref="modal" id="modalAddFarmLONG" :class="{show, 'd-block': modalAddFarmLONG}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalAddFarm('long')">x</button>
                        <h3 class="mb-3">Stake LONG</h3>
                        <h5>Available: {{ farms.long.model.availableBalance }}</h5>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.long.model.formInputAdd">
                                <button v-if="farms.long.btn.addModal == false" @click="setMaxStake('long')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.long.btn.addModal == false">
                            <div class="row px-3">
                                <button @click="openModalAddFarm('long')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmAddFarm('long')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.long.btn.addModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Cut Farm LONG  -->
        <div class="modal fade" ref="modal" id="modalCutFarmLONG" :class="{show, 'd-block': modalCutFarmLONG}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalCutFarm('long')">x</button>
                        <h3 class="mb-3">unstake LONG</h3>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.long.model.formInputCut">
                                <button v-if="farms.long.btn.cutModal == false" @click="setMaxUnStake('long')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.long.btn.cutModal == false">
                            <div class="row px-3">
                                <button @click="openModalCutFarm('long')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmCutFarm('long')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.long.btn.cutModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Add Farm BTA  -->
        <div class="modal fade" ref="modal" id="modalAddFarmBTA" :class="{show, 'd-block': modalAddFarmBTA}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalAddFarm('bta')">x</button>
                        <h3 class="mb-3">Stake BTA</h3>
                        <h5>Available: {{ farms.bta.model.availableBalance }}</h5>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.bta.model.formInputAdd">
                                <button v-if="farms.bta.btn.addModal == false" @click="setMaxStake('bta')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.bta.btn.addModal == false">
                            <div class="row px-3">
                                <button @click="openModalAddFarm('bta')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmAddFarm('bta')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.bta.btn.addModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Cut Farm BTA  -->
        <div class="modal fade" ref="modal" id="modalCutFarmBTA" :class="{show, 'd-block': modalCutFarmBTA}" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content plat rounded-4 text-white">
                    <div class="modal-body px-5">
                        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" @click="openModalCutFarm('bta')">x</button>
                        <h3 class="mb-3">UnStake BTA</h3>
                        <div class="my-4">
                            <div class="p-2">
                                <input type="text" class="form-control" v-model="farms.bta.model.formInputCut">
                                <button v-if="farms.bta.btn.cutModal == false" @click="setMaxUnStake('bta')" class="btn btn-todo w-100 mt-3 p-1">Max</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.bta.btn.cutModal == false">
                            <div class="row px-3">
                                <button @click="openModalCutFarm('bta')" class="btn btn-danger col-xl mx-2">Cancel</button> <button @click="confirmCutFarm('bta')" class="btn btn-success col-xl mx-2">Confirm</button>
                            </div>
                        </div>
                        <div class="my-4" v-if="farms.bta.btn.cutModal == true">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Contract from "../config/contract";
import Web3 from "web3";
import { useToast } from "vue-toastification";
import BigNumber from "bignumber.js";
import numeral from 'numeral';
import apiService from "../services/ApiService";

function initialState() {
    return {
        hover: false,
        hover1a: false,
        hover2: false,
        hover2a: false,
        hover3: false,
        hover3a: false,
        hover4: false,
        hover4a: false,
        hover5: false,
        hover5a: false,
        modalConnectWallet: false,
        modalLogoutWallet: false,
        modalROILONGBNB: false,
        modalROIBTABNB: false,
        modalROILONGBTA: false,
        modalROILONG: false,
        modalROIBTA: false,
        modalAddFarmLONGBNB : false,
        modalCutFarmLONGBNB : false,
        modalAddFarmBTABNB : false,
        modalCutFarmBTABNB : false,
        modalAddFarmLONGBTA : false,
        modalCutFarmLONGBTA : false,
        modalAddFarmLONG : false,
        modalCutFarmLONG : false,
        modalAddFarmBTA : false,
        modalCutFarmBTA : false,
        show: false,
        walletConnect: {
            web3: null,
            MetaMaskId: "1", // main net netID
            netID: null, // user metamask id
            MetaMaskAddress: "", // user Address
            Web3Interval: null,
            AccountInterval: null,
            NetworkInterval: null,
        },
        info: {
            yourLongBalance: 0,
            totalSupply: 500000000,
            maxSupply: 500000000,
            longPrice: 0,
            btaPrice: 0,
            bnbPrice: 0,
            volume24hr: 0,
            longMarketCap: 0,
            TVL: 0,
            aprLongBnb: 0,
            aprBtaBnb: 0,
            aprLongBta: 0,
            aprLong: 0,
            aprBta: 0,
            liquidityLONGBNB: 0,
            liquidityBTABNB: 0,
            liquidityLONGBTA: 0,
            liquidityLONG: 0,
            liquidityBTA: 0,
        },
        farms: {
            longbnb: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            btabnb: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            longbta: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            long: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
            bta: {
                btn: {
                    approval_load: false,
                    approval_text: 'disabled',
                    approval: false,
                    harvest: false,
                    addModal: false,
                    cutModal: false
                },
                model: {
                    totalStaked: 0,
                    longEarned: 0,
                    longEarnedUsd: 0,
                    formInputAdd: 0,
                    formInputCut: 0,
                }
            },
        },
        ROI: {
            longbnb: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            btabnb: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            longbta: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            long: {
                d1: 0, d7: 0, d30: 0, d365: 0
            },
            bta: {
                d1: 0, d7: 0, d30: 0, d365: 0
            }
        }
    }
}

export default {
    name: 'farms',
    components: {
        // VueMetamask,
        Footer, Header
    },
    setup() {
      const toast = useToast();
      return { toast }
    },
    data() {
        return initialState()
    },
    methods: {
        checkWeb3() {
            let web3 = window.web3;
            if (typeof web3 === "undefined") {
                this.walletConnect.web3 = null;
            }
        },
        checkAccounts() {
            if (this.walletConnect.web3 === null) return;
            this.walletConnect.web3.eth.getAccounts((err, accounts) => {
                if (err != null)
                    console.log("NETWORK_ERROR");
                if (accounts.length === 0) {
                    this.walletConnect.MetaMaskAddress = "";
                    console.log("NO_LOGIN");
                    this.walletConnect.netID = null;
                    this.walletConnect.web3 = null;
                    return;
                }
                this.walletConnect.MetaMaskAddress = accounts[0]; // user Address
            });
        },
        checkNetWork() {
            if (this.walletConnect.web3 === null) return;
            this.walletConnect.web3.eth.getChainId((err, netID) => {
                // Main Network: 1
                // Ropsten Test Network: 3
                // Kovan Test Network: 42
                // Rinkeby Test Network: 4
                if (err != null)
                    console.log("NETWORK_ERROR");
                    this.walletConnect.netID = netID; //User MetaMask's current status
                // if (netID === 1) 1 for eth
                if (netID === 1072)
                    console.log("MAINNET");
                if (netID === 3)
                    console.log("ROPSTEN");
                if (netID === 42)
                    console.log("KOVAN");
                if (netID === 4)
                    console.log("RINKEBY");
                if (netID === 97)
                    console.log("BSC TESTNET");
            });

        },
        web3TimerCheck(web3) {
            this.walletConnect.web3 = web3;
            this.checkAccounts();
            this.checkNetWork();
            // this.walletConnect.Web3Interval = setInterval(() => this.checkWeb3(), 5000);
            this.walletConnect.AccountInterval = setInterval(() => this.checkAccounts(), 5000);
            // this.walletConnect.NetworkInterval = setInterval(() => this.checkNetWork(), 5000);
        },
        logoutWeb3() {
            this.walletConnect.web3 =  null;
            this.walletConnect.netID =  null;
            this.walletConnect.Web3Interval =  null;
            this.walletConnect.AccountInterval =  null;
            this.walletConnect.NetworkInterval =  null;
            this.$cookies.remove("autoconnect");
            this.openModal('logoutwallet');
            this.toast.info("successfully logged out");
            Object.assign(this.$data, initialState());
            this.walletConnect.MetaMaskAddress = "Connect Wallet";
            this.getInfo();
        },
        async connectWallet(wallet) {
            if(wallet != 'metamask') return;
            if (window.ethereum) {
                window.web3 = new Web3(window.ethereum);
                try {
                    var self = this;
                    await window.ethereum.enable();
                    this.web3TimerCheck(window.web3);
                    this.$cookies.set("autoconnect","true","1d");  // 60 second after, expire
                    setTimeout(function(){
                        self.getInfo();
                        self.toast.info("successfully login to wallet");
                        self.openModal('connectwallet');
                    }, 300);
                } catch (error) {
                    console.log('REFRESH_PAGE', error)
                    console.log(
                        "USER_DENIED_ACCOUNT_AUTHORIZATION"
                    );
                }
            } else if (window.web3) {
                window.web3 = new Web3(Web3.currentProvider);
                this.web3TimerCheck(window.web3);
            } else {
                this.walletConnect.web3 = null;
                console.log("NO_INSTALL_METAMASK");
                console.error(
                    "Non-Ethereum browser detected. You should consider trying MetaMask!"
                );
            }
        },
        async autoConnect() {
            if (window.ethereum) {
                window.web3 = new Web3(window.ethereum);
                try {
                    var self = this;
                    await window.ethereum.enable();
                    this.web3TimerCheck(window.web3);
                    this.$cookies.set("autoconnect","true","1d");  // 60 second after, expire
                    setTimeout(function(){
                        self.getInfo();
                        self.toast.info("successfully login to wallet");
                    }, 300);
                } catch (error) {
                    console.log('REFRESH_PAGE', error)
                    console.log(
                        "USER_DENIED_ACCOUNT_AUTHORIZATION"
                    );
                }
            } else if (window.web3) {
                window.web3 = new Web3(Web3.currentProvider);
                this.web3TimerCheck(window.web3);
            } else {
                this.walletConnect.web3 = null;
                console.log("NO_INSTALL_METAMASK");
                console.error(
                    "Non-Ethereum browser detected. You should consider trying MetaMask!"
                );
            }
        },
        helperAmountHex(type, amount) {
            if(type == 1){
                // human
                var n = new BigNumber (amount / 1e18);
                return n.toFixed(4);
            }
            else{
                // robot
                var x = new BigNumber (amount * 1e18);
                return x.toFixed();
            }
        },
        helperNumeral(amount, format = null){
            return numeral(amount).format(format);
        },
        helperAddress(address) {
            var str = address.substr(0,6) + '...' + address.substr(address.length - 5);
            return str;
        },
        getInfo() {
            var self = this;
            // get Your Long Balance
            var LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.long.abi, Contract.farms.lpToken.long.contract);
            LpTokenContract.methods.balanceOf(this.walletConnect.MetaMaskAddress).call({})
            .then(function(balance){
                self.info.yourLongBalance = self.helperAmountHex(1, balance);
            });

            apiService.longPrice().then(res => {
                var price = parseFloat(res.data.priceUSD);
                var volume = parseFloat(res.data.volume24hUSD);
                self.info.longPrice = price.toFixed(6);
                self.info.volume24hr = volume.toFixed(6);
                self.info.longMarketCap = 200000000*price;
            })

            apiService.btaPrice().then(res => {
                var price = parseFloat(res.data.priceUSD);
                self.info.btaPrice = price.toFixed(6);
            })

            apiService.bnbPrice().then(res => {
                var price = parseFloat(res.data.priceUSD);
                self.info.bnbPrice = price.toFixed(6);
            })

            this.getFarmsLongBNB();
            this.getFarmsBtaBNB();
            this.getFarmsLongBTA();
            this.getFarmsLong();
            this.getFarmsBTA();
        },
        openModal(modalType){
            if(modalType == 'connectwallet'){
                const body = document.getElementById("modalConnectWallet");
                this.modalConnectWallet = !this.modalConnectWallet;
                this.modalConnectWallet ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(modalType == 'logoutwallet'){
                const body = document.getElementById("modalLogoutWallet");
                this.modalLogoutWallet = !this.modalLogoutWallet;
                this.modalLogoutWallet ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(modalType == 'modalROILONGBNB'){
                // roi calculator
                const body = document.getElementById("modalROILONGBNB");
                this.modalROILONGBNB = !this.modalROILONGBNB;
                this.modalROILONGBNB ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(modalType == 'modalROIBTABNB'){
                // roi calculator
                const body = document.getElementById("modalROIBTABNB");
                this.modalROIBTABNB = !this.modalROIBTABNB;
                this.modalROIBTABNB ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(modalType == 'modalROILONGBTA'){
                // roi calculator
                const body = document.getElementById("modalROILONGBTA");
                this.modalROILONGBTA = !this.modalROILONGBTA;
                this.modalROILONGBTA ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(modalType == 'modalROILONG'){
                // roi calculator
                const body = document.getElementById("modalROILONG");
                this.modalROILONG = !this.modalROILONG;
                this.modalROILONG ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(modalType == 'modalROIBTA'){
                // roi calculator
                const body = document.getElementById("modalROIBTA");
                this.modalROIBTA = !this.modalROIBTA;
                this.modalROIBTA ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
        },
        getFarmsLongBNB() {
            var self = this;
            // get Allowance
            var LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbnb.abi, Contract.farms.lpToken.longbnb.contract);
            LpTokenContract.methods.allowance(self.walletConnect.MetaMaskAddress, Contract.farms.contract).call({})
            .then(function(result){
                if(result <= 0){
                    self.farms.longbnb.btn.approval_text = 'Enabled';
                    self.farms.longbnb.btn.approval_load = false;
                    self.farms.longbnb.btn.approval = false;
                } else {
                    self.farms.longbnb.btn.approval_text = 'Disabled';
                    self.farms.longbnb.btn.approval_load = true;
                    self.farms.longbnb.btn.approval = true;

                    // get balance available to staked
                    LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                    .then(function(result){
                        self.farms.longbnb.model.availableBalance = self.helperAmountHex(1, result);
                    });
                }
            });

            // get Farm amount
            var farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            farmContract.methods.userInfo(Contract.farms.lpToken.longbnb.pid, this.walletConnect.MetaMaskAddress).call({})
            .then(function(result){
                if(parseFloat(result.amount) > 0){
                    self.farms.longbnb.btn.harvest = true;
                    var amountOfStake = result.amount;
                    self.farms.longbnb.model.totalStaked = self.helperAmountHex(1, amountOfStake);

                    farmContract.methods.poolInfo(Contract.farms.lpToken.longbnb.pid).call({})
                    .then(function(result2){
                        var pending = (amountOfStake * result2.accCakePerShare / 1e12 ) - result.rewardDebt;
                        if(pending >= 0){
                            self.farms.longbnb.model.longEarned = self.helperAmountHex(1, pending);

                            apiService.longPrice().then(res => {
                                var price = parseFloat(res.data.priceUSD);
                                self.farms.longbnb.model.longEarnedUsd = price * self.farms.longbnb.model.longEarned;
                            })
                        }
                    })
                }else{
                    self.farms.longbnb.btn.harvest = false;
                    self.farms.longbnb.model.totalStaked = 0;
                }

                self.farms.longbnb.model.formInputAdd = 0;
                self.farms.longbnb.model.formInputCut = 0;
            });

            setTimeout(function(){ self.calculateAPYLONGBNB(); }, 3000);
        },
        getFarmsBtaBNB() {
            var self = this;
            // get Allowance
            var LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.btabnb.abi, Contract.farms.lpToken.btabnb.contract);
            LpTokenContract.methods.allowance(this.walletConnect.MetaMaskAddress, Contract.farms.contract).call({})
            .then(function(result){
                if(result <= 0){
                    self.farms.btabnb.btn.approval_text = 'Enabled';
                    self.farms.btabnb.btn.approval_load = false;
                    self.farms.btabnb.btn.approval = false;
                } else {
                    self.farms.btabnb.btn.approval_text = 'Disabled';
                    self.farms.btabnb.btn.approval_load = true;
                    self.farms.btabnb.btn.approval = true;

                    // get balance available to staked
                    LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                    .then(function(result){
                        self.farms.btabnb.model.availableBalance = self.helperAmountHex(1, result);
                    });
                }
            });

            // get Farm amount
            var farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            farmContract.methods.userInfo(Contract.farms.lpToken.btabnb.pid, this.walletConnect.MetaMaskAddress).call({})
            .then(function(result){
                if(parseFloat(result.amount) > 0){
                    self.farms.btabnb.btn.harvest = true;
                    var amountOfStake = result.amount;
                    self.farms.btabnb.model.totalStaked = self.helperAmountHex(1, amountOfStake);

                    farmContract.methods.poolInfo(Contract.farms.lpToken.btabnb.pid).call({})
                    .then(function(result2){
                        var pending = (amountOfStake * result2.accCakePerShare / 1e12 ) - result.rewardDebt;
                        if(pending >= 0){
                            self.farms.btabnb.model.longEarned = self.helperAmountHex(1, pending);

                            apiService.longPrice().then(res => {
                                var price = parseFloat(res.data.priceUSD);
                                self.farms.btabnb.model.longEarnedUsd = price * self.farms.btabnb.model.longEarned;
                            })
                        }
                    })
                }else{
                    self.farms.btabnb.btn.harvest = false;
                    self.farms.btabnb.model.totalStaked = 0;
                }

                self.farms.btabnb.model.formInputAdd = 0;
                self.farms.btabnb.model.formInputCut = 0;
            });

            setTimeout(function(){ self.calculateAPYBTABNB(); }, 3000);
        },
        getFarmsLongBTA() {
            var self = this;
            // get Allowance
            var LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbta.abi, Contract.farms.lpToken.longbta.contract);
            LpTokenContract.methods.allowance(this.walletConnect.MetaMaskAddress, Contract.farms.contract).call({})
            .then(function(result){
                if(result <= 0){
                    self.farms.longbta.btn.approval_text = 'Enabled';
                    self.farms.longbta.btn.approval_load = false;
                    self.farms.longbta.btn.approval = false;
                } else {
                    self.farms.longbta.btn.approval_text = 'Disabled';
                    self.farms.longbta.btn.approval_load = true;
                    self.farms.longbta.btn.approval = true;

                    // get balance available to staked
                    LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                    .then(function(result){
                        self.farms.longbta.model.availableBalance = self.helperAmountHex(1, result);
                    });
                }
            });

            // get Farm amount
            var farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            farmContract.methods.userInfo(Contract.farms.lpToken.longbta.pid, this.walletConnect.MetaMaskAddress).call({})
            .then(function(result){
                if(parseFloat(result.amount) > 0){
                    self.farms.longbta.btn.harvest = true;
                    var amountOfStake = result.amount;
                    self.farms.longbta.model.totalStaked = self.helperAmountHex(1, amountOfStake);

                    farmContract.methods.poolInfo(Contract.farms.lpToken.longbta.pid).call({})
                    .then(function(result2){
                        var pending = (amountOfStake * result2.accCakePerShare / 1e12 ) - result.rewardDebt;
                        if(pending >= 0){
                            self.farms.longbta.model.longEarned = self.helperAmountHex(1, pending);

                            apiService.longPrice().then(res => {
                                var price = parseFloat(res.data.priceUSD);
                                self.farms.longbta.model.longEarnedUsd = price * self.farms.longbta.model.longEarned;
                            })
                        }
                    })
                }else{
                    self.farms.longbta.btn.harvest = false;
                    self.farms.longbta.model.totalStaked = 0;
                }

                self.farms.longbta.model.formInputAdd = 0;
                self.farms.longbta.model.formInputCut = 0;
            });

            setTimeout(function(){ self.calculateAPYLONGBTA(); }, 3000);
        },
        getFarmsLong() {
            var self = this;
            // get Allowance
            var LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.long.abi, Contract.farms.lpToken.long.contract);
            LpTokenContract.methods.allowance(this.walletConnect.MetaMaskAddress, Contract.farms.contract).call({})
            .then(function(result){
                if(result <= 0){
                    self.farms.long.btn.approval_text = 'Enabled';
                    self.farms.long.btn.approval_load = false;
                    self.farms.long.btn.approval = false;
                } else {
                    self.farms.long.btn.approval_text = 'Disabled';
                    self.farms.long.btn.approval_load = true;
                    self.farms.long.btn.approval = true;

                    // get balance available to staked
                    LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                    .then(function(result){
                        self.farms.long.model.availableBalance = self.helperAmountHex(1, result);
                    });
                }
            });

            // get Farm amount
            var farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            farmContract.methods.userInfo(Contract.farms.lpToken.long.pid, this.walletConnect.MetaMaskAddress).call({})
            .then(function(result){
                if(parseFloat(result.amount) > 0){
                    self.farms.long.btn.harvest = true;
                    var amountOfStake = result.amount;
                    self.farms.long.model.totalStaked = self.helperAmountHex(1, amountOfStake);

                    farmContract.methods.poolInfo(Contract.farms.lpToken.long.pid).call({})
                    .then(function(result2){
                        var pending = (amountOfStake * result2.accCakePerShare / 1e12 ) - result.rewardDebt;
                        if(pending >= 0){
                            self.farms.long.model.longEarned = self.helperAmountHex(1, pending);

                            apiService.longPrice().then(res => {
                                var price = parseFloat(res.data.priceUSD);
                                self.farms.long.model.longEarnedUsd = price * self.farms.long.model.longEarned;
                            })
                        }
                    })
                }else{
                    self.farms.long.btn.harvest = false;
                    self.farms.long.model.totalStaked = 0;
                }

                self.farms.long.model.formInputAdd = 0;
                self.farms.long.model.formInputCut = 0;
            });

            // // get reward amount
            // farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            // farmContract.methods.poolInfo(Contract.farms.lpToken.long.pid).call({})
            // .then(function(poolInfo){
            //     var lpSupply = poolInfo.lpSupply;
            //     var lastRewardBlock = poolInfo.lastRewardBlock;
            //     var cakePerBlock = poolInfo.cakePerBlock;
            //     var allocPoint = poolInfo.allocPoint;
            //     var totalAllocPoint = poolInfo.totalAllocPoint;
            //     var accCakePerShare = poolInfo.accCakePerShare;
            //     self.walletConnect.web3.eth.getBlockNumber().then(function(lastBlockNo){
            //         farmContract.methods.getMultiplier(lastRewardBlock, lastBlockNo).call({})
            //         .then(function(multiplier){
            //             console.log('multiplier' , multiplier)
            //             var cakeReward = multiplier*cakePerBlock*allocPoint/totalAllocPoint;
            //             accCakePerShare = accCakePerShare + (cakeReward * 1e12) / lpSupply;

            //             farmContract.methods.userInfo(Contract.farms.lpToken.long.pid, self.walletConnect.MetaMaskAddress).call({})
            //             .then(function(userInfo){
            //                 if(parseFloat(userInfo.amount) > 0){
            //                     self.farms.long.btn.harvest = true;
            //                     var amountOfStake = userInfo.amount;

            //                     var pendingReward = amountOfStake * accCakePerShare / 1e12 - userInfo.rewardDebt;
            //                     console.log('totalsakte', amountOfStake, pendingReward)
            //                     self.farms.long.model.totalStaked = self.helperAmountHex(1, amountOfStake);
            //                 }else{
            //                     self.farms.long.btn.harvest = false;
            //                     self.farms.long.model.totalStaked = 0;
            //                 }
            //                 self.farms.long.model.formInputAdd = 0;
            //                 self.farms.long.model.formInputCut = 0;
            //             });
            //             console.log('asd', accCakePerShare)
            //         });
            //     });
            // });

            setTimeout(function(){ self.calculateAPYLONG(); }, 3000);
        },
        getFarmsBTA() {
            var self = this;
            var LpTokenContract = "";
            // get Allowance
            LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.bta.abi, Contract.farms.lpToken.bta.contract);
            LpTokenContract.methods.allowance(this.walletConnect.MetaMaskAddress, Contract.farms.contract).call({})
            .then(function(result){
                if(result <= 0){
                    self.farms.bta.btn.approval_text = 'Enabled';
                    self.farms.bta.btn.approval_load = false;
                    self.farms.bta.btn.approval = false;
                } else {
                    self.farms.bta.btn.approval_text = 'Disabled';
                    self.farms.bta.btn.approval_load = true;
                    self.farms.bta.btn.approval = true;

                    // get balance available to staked
                    LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                    .then(function(result){
                        self.farms.bta.model.availableBalance = self.helperAmountHex(1, result);
                    });
                }
            });

            var farmContract = "";
            // get Farm amount
            farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            farmContract.methods.userInfo(Contract.farms.lpToken.bta.pid, this.walletConnect.MetaMaskAddress).call({})
            .then(function(result){
                if(parseFloat(result.amount) > 0){
                    self.farms.bta.btn.harvest = true;
                    var amountOfStake = result.amount;
                    self.farms.bta.model.totalStaked = self.helperAmountHex(1, amountOfStake);
                    farmContract.methods.poolInfo(Contract.farms.lpToken.bta.pid).call({})
                    .then(function(result2){
                        var pending = (amountOfStake * result2.accCakePerShare / 1e12 ) - result.rewardDebt;
                        self.farms.bta.model.longEarned = 0;
                        if(pending >= 0){
                            self.farms.bta.model.longEarned = self.helperAmountHex(1, pending);

                            apiService.longPrice().then(res => {
                                var price = parseFloat(res.data.priceUSD);
                                self.farms.bta.model.longEarnedUsd = price * self.farms.bta.model.longEarned;
                            })
                        }
                    })
                }else{
                    self.farms.bta.btn.harvest = false;
                    self.farms.bta.model.totalStaked = 0;
                }
                self.farms.bta.model.formInputAdd = 0;
                self.farms.bta.model.formInputCut = 0;
            });

            setTimeout(function(){ self.calculateAPYBTA(); }, 3000);
        },
        calculateAPYLONGBNB() {
            var self = this;
            // LONG BNB
            var  LpTokenContract1 = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbnb.abi, Contract.farms.lpToken.longbnb.contract);
            LpTokenContract1.methods.balanceOf(Contract.farms.contract).call({})
            .then(function(lpBalance){
                // get total supply of lp
                LpTokenContract1.methods.totalSupply().call({})
                .then(function(totalSupply){
                    // LpTokenContract.methods.getReserves().call({})
                    LpTokenContract1.methods.getReserves().call({})
                    .then(function(reserves){
                        var assetA = (lpBalance / totalSupply) * reserves._reserve0; // bta
                        var assetB = (lpBalance / totalSupply) * reserves._reserve1; // bnb
                        var totalAssetA = self.helperAmountHex(1, assetA);
                        var totalAssetB = self.helperAmountHex(1, assetB);
                        var totalCapTokenA = 0, totalCapTokenB = 0;

                        apiService.longPrice().then(res => {
                            var price = parseFloat(res.data.priceUSD);
                            var longPrice = price;
                            apiService.bnbPrice().then(res => {
                                var bnbPrice = parseFloat(res.data.priceUSD);
                                totalCapTokenA = totalAssetA * bnbPrice;
                                totalCapTokenB = totalAssetB * longPrice;
                                var totalAllCap = totalCapTokenA + totalCapTokenB;
                                self.info.liquidityLONGBNB = totalAllCap.toFixed(6);

                                self.info.TVL = self.info.TVL + totalAllCap;
                                var calculateAPYLONGBNB = (5*28800*365*100*longPrice) / totalAllCap;
                                if(calculateAPYLONGBNB != Infinity){
                                    self.info.aprLongBnb = calculateAPYLONGBNB;

                                    self.ROI.longbnb.d1 = self.info.aprLongBnb / 365;
                                    self.ROI.longbnb.d7 = (self.info.aprLongBnb / 365) * 7;
                                    self.ROI.longbnb.d30 = (self.info.aprLongBnb / 365) * 30;
                                    self.ROI.longbnb.d365 = self.info.aprLongBnb;
                                }
                            })

                        })
                    })
                })
            });
        },
        calculateAPYBTABNB() {
            // BTA BNB
            var self = this;
            var LpTokenContract2 = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.btabnb.abi, Contract.farms.lpToken.btabnb.contract);
            LpTokenContract2.methods.balanceOf(Contract.farms.contract).call({})
            .then(function(lpBalance){
                // get total supply of lp
                LpTokenContract2.methods.totalSupply().call({})
                .then(function(totalSupply){
                    // LpTokenContract2.methods.getReserves().call({})
                    LpTokenContract2.methods.getReserves().call({})
                    .then(function(reserves){
                        var assetA = (lpBalance / totalSupply) * reserves._reserve1; // bnb
                        var assetB = (lpBalance / totalSupply) * reserves._reserve0; // bta
                        var totalAssetA = self.helperAmountHex(1, assetA);
                        var totalAssetB = self.helperAmountHex(1, assetB);
                        var totalCapTokenA = 0, totalCapTokenB = 0;

                        apiService.longPrice().then(res => {
                            var longPrice = parseFloat(res.data.priceUSD);
                            apiService.bnbPrice().then(res2 => {
                                var bnbPrice = parseFloat(res2.data.priceUSD);
                                apiService.btaPrice().then(res3 => {
                                    var btaPrice = parseFloat(res3.data.priceUSD);
                                    totalCapTokenA = totalAssetA * bnbPrice;
                                    totalCapTokenB = totalAssetB * btaPrice;
                                    var totalAllCap = totalCapTokenA + totalCapTokenB;
                                    self.info.liquidityBTABNB = totalAllCap.toFixed(6);
                                    self.info.TVL = self.info.TVL + totalAllCap;
                                    var calculateAPYBTABNB = (5*28800*365*100*longPrice) / totalAllCap;
                                    if(calculateAPYBTABNB != Infinity){
                                        self.info.aprBtaBnb = calculateAPYBTABNB;

                                        self.ROI.btabnb.d1 = self.info.aprBtaBnb / 365;
                                        self.ROI.btabnb.d7 = (self.info.aprBtaBnb / 365) * 7;
                                        self.ROI.btabnb.d30 = (self.info.aprBtaBnb / 365) * 30;
                                        self.ROI.btabnb.d365 = self.info.aprBtaBnb;
                                    }
                                });
                            })
                        })
                    })
                })
            });
        },
        calculateAPYLONGBTA() {
            // LONG BTA
            var self = this;
            var LpTokenContract3 = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbta.abi, Contract.farms.lpToken.longbta.contract);
            LpTokenContract3.methods.balanceOf(Contract.farms.contract).call({})
            .then(function(lpBalance){
                // get total supply of lp
                LpTokenContract3.methods.totalSupply().call({})
                .then(function(totalSupply){
                    // LpTokenContract3.methods.getReserves().call({})
                    LpTokenContract3.methods.getReserves().call({})
                    .then(function(reserves){
                        // 0 bta 1 long
                        var assetA = (lpBalance / totalSupply) * reserves._reserve0; // bta
                        var assetB = (lpBalance / totalSupply) * reserves._reserve1; // long
                        var totalAssetA = self.helperAmountHex(1, assetA);
                        var totalAssetB = self.helperAmountHex(1, assetB);
                        var totalCapTokenA = 0, totalCapTokenB = 0;

                        apiService.longPrice().then(res => {
                            var longPrice = parseFloat(res.data.priceUSD);
                            apiService.btaPrice().then(res3 => {
                                var btaPrice = parseFloat(res3.data.priceUSD);
                                totalCapTokenA = totalAssetA * btaPrice;
                                totalCapTokenB = totalAssetB * longPrice;
                                var totalAllCap = totalCapTokenA + totalCapTokenB;
                                self.info.liquidityLONGBTA = totalAllCap.toFixed(6);
                                self.info.TVL = self.info.TVL + totalAllCap;
                                var calculateAPYLONGBTA = (2.5*28800*365*100*longPrice) / totalAllCap;
                                if(calculateAPYLONGBTA != Infinity){
                                    self.info.aprLongBta = calculateAPYLONGBTA;

                                    self.ROI.longbta.d1 = self.info.aprLongBta / 365;
                                    self.ROI.longbta.d7 = (self.info.aprLongBta / 365) * 7;
                                    self.ROI.longbta.d30 = (self.info.aprLongBta / 365) * 30;
                                    self.ROI.longbta.d365 = self.info.aprLongBta;
                                }
                            });
                        })
                    })
                })
            });
        },
        calculateAPYLONG() {
            var self = this;
            var LpTokenContract4 = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            LpTokenContract4.methods.poolInfo(Contract.farms.lpToken.long.pid).call({})
            .then(function(poolInfo){
                var lpSupply = self.helperAmountHex(1, poolInfo.lpSupply);
                apiService.longPrice().then(res => {
                    var longPrice = parseFloat(res.data.priceUSD);
                    var totalAllCap = lpSupply * longPrice;
                    var calculateAPYLONG = (0.8*28800*365*100*longPrice) / totalAllCap;
                    self.info.liquidityLONG = totalAllCap.toFixed(6);
                    self.info.TVL = self.info.TVL + totalAllCap;
                    if(calculateAPYLONG != Infinity){
                        self.info.aprLong = calculateAPYLONG;

                        self.ROI.long.d1 = self.info.aprLong / 365;
                        self.ROI.long.d7 = (self.info.aprLong / 365) * 7;
                        self.ROI.long.d30 = (self.info.aprLong / 365) * 30;
                        self.ROI.long.d365 = self.info.aprLong;
                    }
                });
            });
        },
        calculateAPYBTA() {
            var self = this;
            var LpTokenContract4 = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
            LpTokenContract4.methods.poolInfo(Contract.farms.lpToken.bta.pid).call({})
            .then(function(poolInfo){
                var lpSupply = self.helperAmountHex(1, poolInfo.lpSupply);
                apiService.longPrice().then(res => {
                    var longPrice = parseFloat(res.data.priceUSD);
                    apiService.btaPrice().then(res2 => {
                        var btaPrice = parseFloat(res2.data.priceUSD);
                        var totalAllCap = lpSupply * btaPrice;
                        var calculateAPYBTA = (0.8*28800*365*100*longPrice) / totalAllCap;
                        self.info.liquidityBTA = totalAllCap.toFixed(6);
                        self.info.TVL = self.info.TVL + totalAllCap;
                        if(calculateAPYBTA != Infinity){
                            self.info.aprBta = calculateAPYBTA;

                            self.ROI.bta.d1 = self.info.aprBta / 365;
                            self.ROI.bta.d7 = (self.info.aprBta / 365) * 7;
                            self.ROI.bta.d30 = (self.info.aprBta / 365) * 30;
                            self.ROI.bta.d365 = self.info.aprBta;
                        }
                    });
                });
            });
        },
        openModalAddFarm(type){
            if(type == 'longbnb'){
                const body = document.getElementById('modalAddFarmLONGBNB');
                this.modalAddFarmLONGBNB = !this.modalAddFarmLONGBNB;
                this.modalAddFarmLONGBNB ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'btabnb'){
                const body = document.getElementById('modalAddFarmBTABNB');
                this.modalAddFarmBTABNB = !this.modalAddFarmBTABNB;
                this.modalAddFarmBTABNB ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'longbta'){
                const body = document.getElementById('modalAddFarmLONGBTA');
                this.modalAddFarmLONGBTA = !this.modalAddFarmLONGBTA;
                this.modalAddFarmLONGBTA ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'long'){
                const body = document.getElementById('modalAddFarmLONG');
                this.modalAddFarmLONG = !this.modalAddFarmLONG;
                this.modalAddFarmLONG ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'bta'){
                const body = document.getElementById('modalAddFarmBTA');
                this.modalAddFarmBTA = !this.modalAddFarmBTA;
                this.modalAddFarmBTA ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
        },
        openModalCutFarm(type){
            if(type == 'longbnb'){
                const body = document.getElementById('modalCutFarmLONGBNB');
                this.modalCutFarmLONGBNB = !this.modalCutFarmLONGBNB;
                this.modalCutFarmLONGBNB ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'btabnb'){
                const body = document.getElementById('modalCutFarmBTABNB');
                this.modalCutFarmBTABNB = !this.modalCutFarmBTABNB;
                this.modalCutFarmBTABNB ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'longbta'){
                const body = document.getElementById('modalCutFarmLONGBTA');
                this.modalCutFarmLONGBTA = !this.modalCutFarmLONGBTA;
                this.modalCutFarmLONGBTA ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'long'){
                const body = document.getElementById('modalCutFarmLONG');
                this.modalCutFarmLONG = !this.modalCutFarmLONG;
                this.modalCutFarmLONG ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
            if(type == 'bta'){
                const body = document.getElementById('modalCutFarmBTA');
                this.modalCutFarmBTA = !this.modalCutFarmBTA;
                this.modalCutFarmBTA ? body.classList.add("modal-open") : body.classList.remove("modal-open");
                setTimeout(() => (this.show = !this.show), 10);
            }
        },
        setMaxStake(type) {
            var self = this;
            var LpTokenContract = "";
            if(type == 'longbnb'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbnb.abi, Contract.farms.lpToken.longbnb.contract);
                LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result <= 0) return;
                    self.farms.longbnb.model.formInputAdd = self.helperAmountHex(1, result);
                });
            }else if(type == 'btabnb'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.btabnb.abi, Contract.farms.lpToken.btabnb.contract);
                LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result <= 0) return;
                    self.farms.btabnb.model.formInputAdd = self.helperAmountHex(1, result);
                });
            }else if(type == 'longbta'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbta.abi, Contract.farms.lpToken.longbta.contract);
                LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result <= 0) return;
                    self.farms.longbta.model.formInputAdd = self.helperAmountHex(1, result);
                });
            }
            else if(type == 'long'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.long.abi, Contract.farms.lpToken.long.contract);
                LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result <= 0) return;
                    self.farms.long.model.formInputAdd = self.helperAmountHex(1, result);
                });
            }
            else if(type == 'bta'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.bta.abi, Contract.farms.lpToken.bta.contract);
                LpTokenContract.methods.balanceOf(self.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result <= 0) return;
                    self.farms.bta.model.formInputAdd = self.helperAmountHex(1, result);
                });
            }
        },
        setMaxUnStake(type) {
            var self = this;
            var farmContract = "";
            if(type == 'longbnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.userInfo(Contract.farms.lpToken.longbnb.pid, this.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result.amount){
                        var amountOfStake = result.amount;
                        self.farms.longbnb.model.formInputCut = self.helperAmountHex(1, amountOfStake);
                    }
                });
            }else if(type == 'btabnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.userInfo(Contract.farms.lpToken.btabnb.pid, this.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result.amount){
                        var amountOfStake = result.amount;
                        self.farms.btabnb.model.formInputCut = self.helperAmountHex(1, amountOfStake);
                    }
                });
            }else if(type == 'longbta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.userInfo(Contract.farms.lpToken.longbta.pid, this.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result.amount){
                        var amountOfStake = result.amount;
                        self.farms.longbta.model.formInputCut = self.helperAmountHex(1, amountOfStake);
                    }
                });
            }
            else if(type == 'long'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.userInfo(Contract.farms.lpToken.long.pid, this.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result.amount){
                        var amountOfStake = result.amount;
                        self.farms.long.model.formInputCut = self.helperAmountHex(1, amountOfStake);
                    }
                });
            }
            else if(type == 'bta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.userInfo(Contract.farms.lpToken.bta.pid, this.walletConnect.MetaMaskAddress).call({})
                .then(function(result){
                    if(result.amount){
                        var amountOfStake = result.amount;
                        self.farms.bta.model.formInputCut = self.helperAmountHex(1, amountOfStake);
                    }
                });
            }
        },
        confirmAddFarm(type) {
            var self = this;
            var farmContract = "";
            var amountOfDeposit = 0;
            if(type == 'longbnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfDeposit = self.helperAmountHex(2, self.farms.longbnb.model.formInputAdd);
                farmContract.methods.deposit(Contract.farms.lpToken.longbnb.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.longbnb.btn.addModal = false;
                        self.toast.info("Success added longbnb");
                        // close modal add farm
                        self.openModalAddFarm('longbnb');

                        self.getFarmsLongBNB();
                    }
                }).on('transactionHash', function(){
                    self.farms.longbnb.btn.addModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }else if(type == 'btabnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfDeposit = self.helperAmountHex(2, self.farms.btabnb.model.formInputAdd);
                farmContract.methods.deposit(Contract.farms.lpToken.btabnb.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.btabnb.btn.addModal = false;
                        self.toast.info("Success added btabnb");
                        // close modal add farm
                        self.openModalAddFarm('btabnb');

                        self.getFarmsBtaBNB();
                    }
                }).on('transactionHash', function(){
                    self.farms.btabnb.btn.addModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }else if(type == 'longbta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfDeposit = self.helperAmountHex(2, self.farms.longbta.model.formInputAdd);
                farmContract.methods.deposit(Contract.farms.lpToken.longbta.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.longbta.btn.addModal = false;
                        self.toast.info("Success added longbta");
                        // close modal add farm
                        self.openModalAddFarm('longbta');

                        self.getFarmsLongBTA();
                    }
                }).on('transactionHash', function(){
                    self.farms.longbta.btn.addModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }else if(type == 'long'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfDeposit = self.helperAmountHex(2, self.farms.long.model.formInputAdd);
                farmContract.methods.deposit(Contract.farms.lpToken.long.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.long.btn.addModal = false;
                        self.toast.info("Success added long");
                        // close modal add farm
                        self.openModalAddFarm('long');

                        self.getFarmsLong();
                    }
                }).on('transactionHash', function(){
                    self.farms.long.btn.addModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }else if(type == 'bta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfDeposit = self.helperAmountHex(2, self.farms.bta.model.formInputAdd);
                farmContract.methods.deposit(Contract.farms.lpToken.bta.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.bta.btn.addModal = false;
                        self.toast.info("Success added bta");
                        // close modal add farm
                        self.openModalAddFarm('bta');

                        self.getFarmsBTA();
                    }
                }).on('transactionHash', function(){
                    self.farms.bta.btn.addModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
        },
        confirmCutFarm(type) {
            var self = this;
            var farmContract = "";
            var amountOfWithdraw = "";
            if(type == 'longbnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfWithdraw = self.helperAmountHex(2, self.farms.longbnb.model.formInputCut);
                farmContract.methods.withdraw(Contract.farms.lpToken.longbnb.pid, amountOfWithdraw).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.longbnb.btn.cutModal = false;
                        self.toast.info("Success unstake longbnb");
                        // close modal cut farm
                        self.openModalCutFarm('longbnb');

                        self.getFarmsLongBNB();
                    }
                }).on('transactionHash', function(){
                    self.farms.longbnb.btn.cutModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'btabnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfWithdraw = self.helperAmountHex(2, self.farms.btabnb.model.formInputCut);
                farmContract.methods.withdraw(Contract.farms.lpToken.btabnb.pid, amountOfWithdraw).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.btabnb.btn.cutModal = false;
                        self.toast.info("Success unstake btabnb");
                        // close modal cut farm
                        self.openModalCutFarm('btabnb');

                        self.getFarmsBtaBNB();
                    }
                }).on('transactionHash', function(){
                    self.farms.btabnb.btn.cutModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'longbta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfWithdraw = self.helperAmountHex(2, self.farms.longbta.model.formInputCut);
                farmContract.methods.withdraw(Contract.farms.lpToken.longbta.pid, amountOfWithdraw).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.longbta.btn.cutModal = false;
                        self.toast.info("Success unstake longbta");
                        // close modal cut farm
                        self.openModalCutFarm('longbta');

                        self.getFarmsLongBTA();
                    }
                }).on('transactionHash', function(){
                    self.farms.longbta.btn.cutModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'long'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfWithdraw = self.helperAmountHex(2, self.farms.long.model.formInputCut);
                farmContract.methods.withdraw(Contract.farms.lpToken.long.pid, amountOfWithdraw).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.long.btn.cutModal = false;
                        self.toast.info("Success unstake long");
                        // close modal cut farm
                        self.openModalCutFarm('long');

                        self.getFarmsLong();
                    }
                }).on('transactionHash', function(){
                    self.farms.long.btn.cutModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'bta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                amountOfWithdraw = self.helperAmountHex(2, self.farms.bta.model.formInputCut);
                farmContract.methods.withdraw(Contract.farms.lpToken.bta.pid, amountOfWithdraw).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.bta.btn.cutModal = false;
                        self.toast.info("Success unstake bta");
                        // close modal cut farm
                        self.openModalCutFarm('bta');
                        self.getFarmsBTA();
                    }
                }).on('transactionHash', function(){
                    self.farms.bta.btn.cutModal = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
        },
        harvestFarm(type) {
            var self = this;
            var farmContract = "";
            var amountOfDeposit = "0";

            if(type == 'longbnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.deposit(Contract.farms.lpToken.longbnb.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.toast.info("Success harvest longbnb");
                    }
                }).on('transactionHash', function(){
                    self.toast.info("Waiting transaction to confirmation farm");
                })
            }
            if(type == 'btabnb'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.deposit(Contract.farms.lpToken.btabnb.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.toast.info("Success harvest btabnb");
                    }
                }).on('transactionHash', function(){
                    self.toast.info("Waiting transaction to confirmation farm");
                })
            }
            if(type == 'longbta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.deposit(Contract.farms.lpToken.longbta.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.toast.info("Success harvest longbta");
                    }
                }).on('transactionHash', function(){
                    self.toast.info("Waiting transaction to confirmation farm");
                })
            }
            if(type == 'long'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.deposit(Contract.farms.lpToken.long.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.toast.info("Success harvest long");
                    }
                }).on('transactionHash', function(){
                    self.toast.info("Waiting transaction to confirmation farm");
                })
            }
            if(type == 'bta'){
                farmContract = new this.walletConnect.web3.eth.Contract(Contract.farms.abi, Contract.farms.contract);
                farmContract.methods.deposit(Contract.farms.lpToken.bta.pid, amountOfDeposit).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.toast.info("Success harvest bta");
                    }
                }).on('transactionHash', function(){
                    self.toast.info("Waiting transaction to confirmation farm");
                })
            }
        },
        enableFarms(type) {
            var self = this;
            if (!this.walletConnect.web3) return;

            var LpTokenContract = "";
            if(type == 'longbnb'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbnb.abi, Contract.farms.lpToken.longbnb.contract);
                LpTokenContract.methods.approve(Contract.farms.contract, Contract.farms.allowance).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.longbnb.btn.approval = true;
                        self.toast.info("Success enabled farm");
                    }
                }).on('transactionHash', function(){
                    self.farms.longbnb.btn.approval_load = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'btabnb'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.btabnb.abi, Contract.farms.lpToken.btabnb.contract);
                LpTokenContract.methods.approve(Contract.farms.contract, Contract.farms.allowance).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.btabnb.btn.approval = true;
                        self.toast.info("Success enabled farm");
                    }
                }).on('transactionHash', function(){
                    self.farms.btabnb.btn.approval_load = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'longbta'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.longbta.abi, Contract.farms.lpToken.longbta.contract);
                LpTokenContract.methods.approve(Contract.farms.contract, Contract.farms.allowance).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.longbta.btn.approval = true;
                        self.toast.info("Success enabled farm");
                    }
                }).on('transactionHash', function(){
                    self.farms.longbta.btn.approval_load = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'long'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.long.abi, Contract.farms.lpToken.long.contract);
                LpTokenContract.methods.approve(Contract.farms.contract, Contract.farms.allowance).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.long.btn.approval = true;
                        self.toast.info("Success enabled farm");
                    }
                }).on('transactionHash', function(){
                    self.farms.long.btn.approval_load = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
            if(type == 'bta'){
                LpTokenContract = new this.walletConnect.web3.eth.Contract(Contract.farms.lpToken.bta.abi, Contract.farms.lpToken.bta.contract);
                LpTokenContract.methods.approve(Contract.farms.contract, Contract.farms.allowance).send({from: this.walletConnect.MetaMaskAddress})
                .on('confirmation', function(confirmationNumber){
                    if(confirmationNumber == 1){
                        self.farms.bta.btn.approval = true;
                        self.toast.info("Success enabled farm");
                    }
                }).on('transactionHash', function(){
                    self.farms.bta.btn.approval_load = true;
                    self.toast.info("Waiting transaction to confirmation");
                })
            }
        },
    },
    created() {
        document.title = 'Farms | Long Asset';
        this.walletConnect.MetaMaskAddress = "Connect Wallet ";
    },
    mounted() {
        if(this.$cookies.get('autoconnect')){
            this.autoConnect();
        }
    }
}
</script>

<style scoped>
.headImg{
    width: 80px;
}
.taglineImg{
    width: 200px;
}
.sideImg{
    width: 100%;
}
.form-check-inline {
    display: inline-block;
    padding-left: 0;
    margin-right: .75rem;
}
.namewallet{
    position: relative;
    left: 8px;
}
.address-btn{
    white-space: nowrap;
    padding: 8px 20px;
    /* text-overflow: ellipsis; */
    min-width:150px;
    max-width: 280px;
    overflow: hidden;
}
button.btn-enable {
    background: #fbe38d;
    padding: 10px 30px;
    border-radius: 10px;
    color: #000;
    z-index: 2;
    text-decoration: none;
}
button.btn-harvest {
    background: #fbe38d;
    color: #000;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 14px;
    position: absolute;
    right: 15px;
    bottom: 15px;
}
button:disabled.btn-harvest{
     opacity: 0.7;
}
.plus-btn,.min-btn {
    padding: 5px;
    background: #fbe38d;
    color: black;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    cursor:pointer;
}
.modal {
    background: #0101019c;
}
.min-btn.disabled{
    opacity: 0.7;
}
p.green.med-text {
    font-size: 14px;
}
@media(max-width:991px){
  div#modalConnectWallet .px-5 {
      padding: 10px!important;
  }
  div#modalConnectWallet p.med-text {
    text-align: left;
    }
  div#modalConnectWallet button.btn-close.text-white {
    position: absolute;
    top: 3px;
    right: 5px;
    }
    button.btn-harvest {
      padding: 5px!important;
      border-radius: 5px!important;
      display: block;
      font-size: 12px;
  }
}
</style>
